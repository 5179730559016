// const baseApi = axios.create({
// 	baseURL: process.env.VUE_APP_API_URL,
// })

import axios from 'axios';
import router from '../router'
import Constants from '../constants/constants';
import * as LocalStorage from "../constants/localStorage";
const apiPrefix = process.env.VUE_APP_API_URL;
const apiEnterprisePrefix = process.env.VUE_APP_ENTERJOIN_API_URL;

function baseApi(apiUrl) {
  return axios.create({
    baseURL: apiUrl,
    timeout: 5000,
    headers: {
        'Content-Type': 'application/json',
        'UserType': 'company',
    }
  })
}

function getAccessTokenHeader() {
  let auth = LocalStorage.getStorage(LocalStorage.USER_AUTHORIZATION);
  return {
      headers: {
          'Authorization': auth
      }
  }
}


function successStatusCheck(response, resolve) {
  if (response.status == 200) {
    resolve(response);
  } else if (response.status == 401) {
    router.push('/');
  } else {
    resolve('error');
  }
}

function failStatusCheck(err, reject) {
  if (err.response && err.response.status == 401) {
    router.push('/');
  } else {
    reject(err)
  }
}

export function sendInquire(data) {
  return new Promise((resolve, reject) => {
    return baseApi(apiPrefix).post(Constants.INQUIRE_API, data)
        .then((response) => {
            successStatusCheck(response, resolve)
        }).catch(err => {
            failStatusCheck(err, reject)
        });
    }
  )
}


export function getTermsProtectorVersion() {
  return new Promise((resolve, reject) => {
      return baseApi(apiPrefix).get(Constants.TERMS_PROTECTOR_API)
          .then((response) => {
              successStatusCheck(response, resolve)
          }).catch(err => {
              failStatusCheck(err, reject)
          });
    }
  )
}

export function getPolicyProtectorVersion() {
  return new Promise((resolve, reject) => {
      return baseApi(apiPrefix).get(Constants.POLICY_PROTECTOR_API)
          .then((response) => {
              successStatusCheck(response, resolve)
          }).catch(err => {
              failStatusCheck(err, reject)
          });
    }
  )
}

export function getTermsCaregiverVersion() {
  return new Promise((resolve, reject) => {
          return baseApi(apiPrefix).get(Constants.TERMS_CAREGIVER_API)
              .then((response) => {
                  successStatusCheck(response, resolve)
              }).catch(err => {
                  failStatusCheck(err, reject)
              });
      }
  )
}

export function getPolicyCaregiverVersion() {
  return new Promise((resolve, reject) => {
          return baseApi(apiPrefix).get(Constants.POLICY_CAREGIVER_API)
              .then((response) => {
                  successStatusCheck(response, resolve)
              }).catch(err => {
                  failStatusCheck(err, reject)
              });
      }
  )
}

export function getTermsLocationService() {
  return new Promise((resolve, reject) => {
          return baseApi(apiPrefix).get(Constants.TERMS_LOCATION_API)
              .then((response) => {
                  successStatusCheck(response, resolve)
              }).catch(err => {
                  failStatusCheck(err, reject)
              });
      }
  )
}

export function getTerms(termsId) {
  return new Promise((resolve, reject) => {
          return baseApi(apiPrefix).get(`${Constants.TERMS_API}/${termsId}`)
              .then((response) => {
                  successStatusCheck(response, resolve)
              }).catch(err => {
                  failStatusCheck(err, reject)
              });
      }
  )
}

export function getPolicy(policyId) {
  return new Promise((resolve, reject) => {
          return baseApi(apiPrefix).get(`${Constants.POLICY_API}/${policyId}`)
              .then((response) => {
                  successStatusCheck(response, resolve)
              }).catch(err => {
                  failStatusCheck(err, reject)
              });
      }
  )
}

/**
 * 기업용 케어메이트 현황 조회
 * -----------------------------------------------------------------------------------------------------------------
 */
export function enterpriseUserRate() {
  return new Promise((resolve, reject) => {
          return baseApi(apiEnterprisePrefix).get(Constants.ENTERPRISE_USER_RATE)
              .then((response) => {
                  successStatusCheck(response, resolve)
              }).catch(err => {
                  failStatusCheck(err, reject)
              });
      }
  )
}
/**
 * 약관 조회
 * -----------------------------------------------------------------------------------------------------------------
 *
 * @param type : 'agree': 케어네이션 서비스 이용약관 'user': 기업정보 처리방침 'personal': 기업정보 수집 및 이용 동의서
 *               'personal_select': 기업정보 수집 및 이용 동의서(선택) 'marketing_select': 기업정보 수집 및 이용동의서
 *               'all' : 전체조회
 */
export function enterpriseUserTerm(type) {
    return new Promise((resolve, reject) => {
            return baseApi(apiEnterprisePrefix).get(Constants.ENTERPRISE_USER_TERMS + "/?type=" + type, getAccessTokenHeader())
                .then((response) => {
                    successStatusCheck(response, resolve)
                }).catch(err => {
                    failStatusCheck(err, reject)
                });
        }
    )
}
/**
 * 기업용 회원가입
 * -----------------------------------------------------------------------------------------------------------------
 *
 * @param data : 회원가입 입력 정보 formData
 * @param userId : 회원가입 이메일 아이디
 */
export function enterpriseSignUp(data, userId) {
  return new Promise((resolve, reject) => {
          return baseApi(apiEnterprisePrefix).post(`${Constants.ENTERPRISE_SIGNUP_API}/${userId}`, data, {
              headers: {
                  'Content-Type': 'multipart/form-data'
              }})
              .then((response) => {
                  successStatusCheck(response, resolve)
              }).catch(err => {
                  failStatusCheck(err, reject)
              });
      }
  )
}
/**
 * 기업용 사업자등록번호 인증
 * -----------------------------------------------------------------------------------------------------------------
 *
 * @param data : Object Data
 */
export function enterpriseUserRegistrationNumber(data) {
  return new Promise((resolve, reject) => {
          return baseApi(apiEnterprisePrefix).post(Constants.ENTERPRISE_USER_REGISTRATION_NUMBER, data, getAccessTokenHeader())
              .then((response) => {
                  successStatusCheck(response, resolve)
              }).catch(err => {
                  failStatusCheck(err, reject)
              });
      }
  )
}

/**
 * 기업용 sms 본인 인증 문자 발송
 * -----------------------------------------------------------------------------------------------------------------
 *
 * @param data : { phone: 핸드폰 번호 ex) 01011111111 }
 */
export function enterpriseSendSMS(data) {
  return new Promise((resolve, reject) => {
          return baseApi(apiEnterprisePrefix).post(Constants.ENTERPRISE_SEND_SMS, data)
              .then((response) => {
                  successStatusCheck(response, resolve)
              }).catch(err => {
                  failStatusCheck(err, reject)
              });
      }
  )
}

/**
 * 기업용 sms 본인 인증 인증번호 확인
 * -----------------------------------------------------------------------------------------------------------------
 *
 * @param data : { phone: 핸드폰 번호, code: 본인인증 코드 }
 */
export function enterpriseCertSMS(data) {
  return new Promise((resolve, reject) => {
          return baseApi(apiEnterprisePrefix).post(Constants.ENTERPRISE_CERT_SMS, data)
              .then((response) => {
                  successStatusCheck(response, resolve)
              }).catch(err => {
                  failStatusCheck(err, reject)
              });
      }
  )
}

/**
 * 기업용 아이디 중복 확인
 * -----------------------------------------------------------------------------------------------------------------
 *
 * @param data : { email: 가입 이메일 }
 */
export function enterpriseCheckDuplicateId(data) {
  return new Promise((resolve, reject) => {
          return baseApi(apiEnterprisePrefix).post(Constants.ENTERPRISE_CHECK_DUPLICATE_ID, data)
              .then((response) => {
                  successStatusCheck(response, resolve)
              }).catch(err => {
                  failStatusCheck(err, reject)
              });
      }
  )
}

export default {
  sendInquire, getTermsProtectorVersion, getPolicyProtectorVersion, getTermsCaregiverVersion, getPolicyCaregiverVersion, getTerms, getPolicy, enterpriseSignUp, enterpriseUserRegistrationNumber, enterpriseUserRate,enterpriseUserTerm, enterpriseSendSMS,enterpriseCertSMS, enterpriseCheckDuplicateId, getTermsLocationService
}