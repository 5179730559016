<template>
  <main>
    <section class="service prot">
      <article class="subMain mobileBg">
        <div class="basicWrap clear">
          <div class="subMain__tit">
            <h2>간병인 찾을 땐<br />케어네이션</h2>
            <p>프로필과 후기를 보고 원하는 간병인을 직접 선택!</p>
            <a :href="googleStore" target="_blank" class="findBtn" v-if="this.storeInfo == 'window' || this.storeInfo == 'android'">바로 간병인 찾기</a>
            <a :href="appleStore" target="_blank" class="findBtn" v-if="this.storeInfo == 'mac' || this.storeInfo == 'ios' || this.storeInfo == 'ipad'"
              >바로 간병인 찾기</a
            >
            <div class="subMain__tit--storeBtn">
              <a :href="googleStore" class="google" target="_blank">구글 플레이 스토어</a>
              <a :href="appleStore" class="apple" target="_blank">앱 스토어</a>
            </div>
          </div>
          <article class="subMain__img">
            <img src="@/assets/img/img_submain_01.png" alt="간병인 찾을 땐 케어네이션" />
          </article>
        </div>
      </article>
      <!-- <article class="videoSlideWrap">
        <div class="basicWrap">
          <div class="subMain__tit">
            <h2>  
              보다 좋은 간병 문화를 만드는<br />
              케어네이션
            </h2>
          </div>
          <div class="videoSlideWrap__frame clear">
            <div class="videoSlideWrap__frame--tit">
              <h3>{{products[productNum].title}}</h3>
            </div>
            <iframe
              width="100%"
              height="100%"
              :src="products[productNum].video.replace('watch?v=', 'embed/').replace('&feature=youtu.be', '')"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <ul class="videoSlideWrap__frame--sumnail">
            <li @click="productNumChange(1)" v-if="productNum !== 1">
              <img
                src="@/assets/img/img_sum01.png"
                alt="간병인보험VS간병인배상책임보험, 무슨 차이인가요?"
              />
            </li>
            <li @click="productNumChange(2)" v-if="productNum !== 2">
              <img
                src="@/assets/img/img_sum02.png"
                alt="간병비 입찰제를 해야 하는 이유? 간병비의 기준을 알아보자! "
              />
              <button type="button" class="video02" />
            </li>
            <li @click="productNumChange(3)" v-if="productNum !== 3">
              <img
                src="@/assets/img/img_sum03.png"
                alt="요양보호사 vs 간병인, 무엇이 더 좋을까?"
              />
              <button type="button" class="video03" />
            </li>
            <li @click="productNumChange(0)" v-if="productNum !== 0">
              <img
                src="@/assets/img/img_sum08.png"
                alt="간병인을 불렀더니 엄마에게 친구가 생겼어요!"
              />
              <button type="button" class="video00" />
            </li>
          </ul>
        </div>
      </article> -->
      <article class="service__reason">
        <div class="basicWrap">
          <div class="subMain__tit">
            <h2>
              간병인 찾기에<br />
              케어네이션이 좋은 이유!
            </h2>
          </div>
          <div class="service__reason--check"></div>
        </div>
        <div class="fullSlideWrap">
          <slick :options="slickOptions01" class="fullSlideWrap__content" ref="slick">
            <div class="fullSlideWrap__content--con con01">
              <small>01</small>
              <h3>
                지원한 간병인의 프로필을 확인 후,<br />
                직접 선택!
              </h3>
              <p>
                프로필 및 후기를 통해 내가 원하는 간병인을<br />
                직접 선택할 수 있습니다.
              </p>
            </div>
            <div class="fullSlideWrap__content--con con02">
              <small>02</small>
              <h3>
                간병비 부담을 줄이는<br />
                카드결제
              </h3>
              <p>
                케어네이션에서는 카드 결제는 물론 <br />
                할부 결제까지 가능합니다.
              </p>
            </div>
            <div class="fullSlideWrap__content--con con03">
              <small>03</small>
              <h3>
                믿을 수 있는<br />
                간병인배상책임보험
              </h3>
              <p>
                케어네이션의 모든 간병인 파트너는<br />
                간병인배상책임보험에 의무로 가입합니다.
              </p>
            </div>
            <div class="fullSlideWrap__content--con con04">
              <small>04</small>
              <h3>
                간병인 입찰제를 <br />
                통한 간병비 측정
              </h3>
              <p>
                간병비의 투명성을 위해<br />
                보호자와 간병인의 합의된 입찰제로 진행합니다.
              </p>
            </div>
          </slick>
          <div class="slideBtnWrap">
            <button type="button" class="prev">이전</button>
            <button type="button" class="next">다음</button>
          </div>
        </div>
      </article>
      <article class="service__step">
        <div class="basicWrap">
          <div class="subMain__tit">
            <h2>
              간병인 매칭,<br />
              쉽고 빠른 3단계!
            </h2>
            <p>케어네이션의 간병인 매칭은 간단하지만 확실합니다.</p>
          </div>
          <ul>
            <li class="step01">
              <small>STEP 01</small>
              <h3>
                간병인 구인 공고<br />
                등록 및 대기
              </h3>
            </li>
            <li class="step02">
              <small>STEP 02</small>
              <h3>
                지원한 간병인<br />
                정보 확인 및 선택
              </h3>
            </li>
            <li class="step03">
              <small>STEP 03</small>
              <h3>간병인 매칭 완료</h3>
            </li>
          </ul>
        </div>
      </article>
      <article class="service__paySer">
        <div class="basicWrap clear">
          <div class="subMain__tit">
            <h2>
              안전하고 편리한<br />
              안심결제 서비스
            </h2>
            <p>
              케어네이션에서는 다년간의 경력을 인정받아<br />
              <b>금융감독원 허가를 통한 PG서비스를 연동</b>하였습니다.<br />
              케어네이션 안심결제로 보다 안전하게 카드 결제를 이용해 보세요!
            </p>
          </div>
          <div class="subMain__img">
            <img src="@/assets/img/img_pay.png" alt="안전하고 편리한 안심결제 서비스" />
          </div>
        </div>
      </article>
      <article class="service__review">
        <div class="basicWrap">
          <div class="subMain__tit">
            <h2>
              케어네이션으로<br />
              간병인에 대한<br />
              믿음이 생겼습니다.
            </h2>
            <p>
              케어네이션을 이용하시는<br />
              실제 보호자분들의 후기입니다.
            </p>
          </div>
        </div>
        <div class="cardSlideWrap">
          <div class="basicWrap">
            <slick class="cardSlideWrap__con" :options="slickOptions02">
              <div class="cardSlideWrap__con--list">
                <div>
                  <h3>보호자 이*정님의 후기</h3>
                  <p>
                    정말 친절하시고 환자 입장에서<br />
                    노력하시는 좋은 분입니다.<br />
                    간병인분께 진심으로 감사합니다.
                  </p>
                  <i class="star01"></i>
                </div>
                <div class="clear">
                  <figure class="profile01"></figure>
                  <div>
                    <h4>서*경 간병인</h4>
                    <p>서울아산병원</p>
                  </div>
                </div>
              </div>
              <div class="cardSlideWrap__con--list">
                <div>
                  <h3>보호자 김*진님의 후기</h3>
                  <p>
                    경력뿐만 아니라 노련함, 성실, 성품 또한<br />너무 좋으셔서 마음 편한 돌봄을 받아 <br />고마운 마음 전하고 싶어요! <br />적극 추천합니다.
                  </p>
                  <i class="star02"></i>
                </div>
                <div class="clear">
                  <figure class="profile02"></figure>
                  <div>
                    <h4>정*욱 간병인</h4>
                    <p>인제대학교 상계백병원</p>
                  </div>
                </div>
              </div>
              <div class="cardSlideWrap__con--list">
                <div>
                  <h3>보호자 오*화님의 후기</h3>
                  <p>너무 성실하고 좋으십니다.<br />간병 받은 친정엄마가 친자매같이 <br />돌봐줬다며 엄청 만족하셨어요.</p>
                  <i class="star01"></i>
                </div>
                <div class="clear">
                  <figure class="profile03"></figure>
                  <div>
                    <h4>김*자 간병인</h4>
                    <p>삼성서울병원</p>
                  </div>
                </div>
              </div>
              <div class="cardSlideWrap__con--list">
                <div>
                  <h3>보호자 최*자님의 후기</h3>
                  <p>
                    환자를 먼저 생각하시는<br />
                    실력과 경험을 겸비하신 간병인님으로<br />
                    매우 만족했습니다.
                  </p>
                  <i class="star01"></i>
                </div>
                <div class="clear">
                  <figure class="profile04"></figure>
                  <div>
                    <h4>이*용 간병인</h4>
                    <p>카톨릭대학교 <br />서울성모병원</p>
                  </div>
                </div>
              </div>
            </slick>
          </div>
          <div class="slideBtnWrap">
            <button type="button" class="prev">이전</button>
            <button type="button" class="next">다음</button>
          </div>
        </div>
      </article>
    </section>
  </main>
</template>

<script>
import * as Utils from '../../constants/utils';
import Slick from 'vue-slick';
import 'slick-carousel/slick/slick.css';
import findCpn from '@/assets/js/findCpn';
export default {
  components: { Slick },
  data() {
    return {
      storeInfo: Utils.storeType(),
      googleStore: 'https://v94bh.app.link/7WmTNXYN0Jb',
      appleStore: 'https://v94bh.app.link/ADD9pPbO0Jb',
      productNum: 0,
      // products : [
      //   {title: '간병인을 불렀더니 엄마에게 친구가 생겼어요!', imgSrc: require('@/assets/img/img_sum01.png'), video: 'https://www.youtube-nocookie.com/embed/yMx3yvmFrKo' },
      //   {title: '간병인보험vs간병인배상책임보험, 무슨 차이인가요?', imgSrc: require('@/assets/img/img_sum01.png'), video: 'https://www.youtube-nocookie.com/embed/eKHh7lno8AU' },
      //   {title: '간병비 입찰제가 필요한 이유는? 간병비의 모든 것!', imgSrc: require('@/assets/img/img_sum01.png'), video: 'https://www.youtube-nocookie.com/embed/0rVnHioMdE0' },
      //   {title: '간병비, 아직도 현금결제 하세요? 이제 카드결제하세요!', imgSrc: require('@/assets/img/img_sum01.png'), video: 'https://www.youtube-nocookie.com/embed/h3F3RtDnpT4'},
      // ],
      findCpn: findCpn,
      slickOptions01: {
        slidesToShow: 1,
        draggable: false,
        prevArrow: '.slideBtnWrap .prev',
        nextArrow: '.slideBtnWrap .next',
        dots: true,
        dotsClass: 'clearfix',
        appendDots: '.service__reason--check',
        customPaging: function (slide, index) {
          return '<button type="button">' + findCpn[index].title + '</button>';
        },
      },
      slickOptions02: {
        slidesToShow: 3,
        slidesToScroll: 1,
        draggable: false,
        prevArrow: '.cardSlideWrap .prev',
        nextArrow: '.cardSlideWrap .next',
        responsive: [
          {
            breakpoint: 1023,
            settings: {
              slidesToShow: 2,
              draggable: true,
            },
          },
          {
            breakpoint: 723,
            settings: {
              slidesToShow: 1,
              autoplay: true,
              autoplaySpeed: 3000,
            },
          },
        ],
      },
    };
  },
  methods: {
    // productNumChange(index) {
    //   this.productNum = index;
    // }
  },
};
</script>

<style>
.protector .slick-active:after {
  width: 100%;
  height: 4px;
  background-color: #a47768;
  opacity: 1 !important;
}
</style>
