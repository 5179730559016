const diseaseArr = [
  {
    name: '치매',
    min: 113000,
    average: 124000,
    max: 159000,
  },
  {
    name: '마비(편마비, 전신)',
    min: 112000,
    average: 125000,
    max: 163000,
  },
  {
    name: '폐암',
    min: 110000,
    average: 120000,
    max: 149000,
  },
  {
    name: '뇌출혈',
    min: 122000,
    average: 135000,
    max: 173000,
  },
];

export default diseaseArr;
