<template>
  <div id="app">
    <CommonHeader />
    <router-view></router-view>
    <CommonFooter />
  </div>
</template>

<script>
import CommonHeader from './components/common/CommonHeader';
import CommonFooter from './components/common/CommonFooter';
// import MainPage from './components/main/MainPage'
export default {
  name: 'app',
  components : {
    CommonHeader : CommonHeader,
    CommonFooter : CommonFooter,
  }
}
</script>