export const INQUIRE_API = "/inquire"; //## 문의하기
export const TERMS_PROTECTOR_API = "/terms/protector/agree"; //## 보호자 이용약관 버전 정보
export const POLICY_PROTECTOR_API = "/terms/protector/user"; //## 보호자 개인정보처리방침 버전 정보
export const TERMS_CAREGIVER_API = "/terms/caregiver/agree"; //## 간병인 이용약관 버전 정보
export const POLICY_CAREGIVER_API = "/terms/caregiver/user"; //## 간병인 개인정보처리방침 버전 정보
export const TERMS_LOCATION_API = "/terms/common/location"; //## 위치기반서비스 이용약관 정보
export const TERMS_API = "/terms"; //## 이용약관 상세
export const POLICY_API = "/terms"; //## 개인정보처리방침 상세
export const ENTERPRISE_USER_RATE = "/common/user/rate"; //## 케어메이트 현황 조회
export const ENTERPRISE_USER_REGISTRATION_NUMBER = "/common/user/registration-number"; //## 기업용 사업자등록번호 인증
export const ENTERPRISE_USER_TERMS = "/common/terms"; //## 기업용 약관 조회
export const ENTERPRISE_SEND_SMS = "/sms/code"; //## 기업용 회원가입 인증번호 발송
export const ENTERPRISE_CERT_SMS = "/confirm/sms/code"; //## 기업용 회원가입 인증번호 확인
export const ENTERPRISE_CHECK_DUPLICATE_ID = "/check/duplicate/id"; //## 기업용 아이디 중복 확인
export const ENTERPRISE_SIGNUP_API = "/signup"; //## 기업용 회원가입
export default {
    INQUIRE_API,
    TERMS_PROTECTOR_API,
    POLICY_PROTECTOR_API,
    TERMS_CAREGIVER_API,
    POLICY_CAREGIVER_API,
    TERMS_LOCATION_API,
    TERMS_API,
    POLICY_API,
    ENTERPRISE_USER_RATE,
    ENTERPRISE_USER_REGISTRATION_NUMBER,
    ENTERPRISE_USER_TERMS,
    ENTERPRISE_SEND_SMS,
    ENTERPRISE_CERT_SMS,
    ENTERPRISE_CHECK_DUPLICATE_ID,
    ENTERPRISE_SIGNUP_API
};