// # 메인화면 서비스컨텐츠 슬라이더 내용
export default [
  {
    title: "간병 서비스",
    contents: `<strong>간병 서비스</strong>
      <p>
        간병인 프로필/후기/간병비 확인 후, <br />
        카드로 간편 결제하세요
      </p>
      <span>
        대한민국 1등 간병인 찾기 <br />
        오늘도 더 좋은 간병 문화를 만들어갑니다
      </span>`,
  },
  {
    title: "동행 서비스",
    contents: `<strong>동행 서비스</strong>
      <p>
        집부터 병원까지,
        <br />
        든든한 동행인을 찾아보세요
      </p>
      <span>
        부모님 동행, 마음까지 함께 해 줄 사람 <br />
        케어네이션에 있습니다
      </span>`,
  },
  {
    title: "가사돌봄 서비스",
    contents: `<strong>가사돌봄 서비스</strong>
      <p>
      가사부터 돌봄까지<br />
      필요한 만큼 편리하게 이용해 보세요
      </p>
      <span>
        맞춤형 가사돌봄을 통해 <br />
        케어메이트가 따뜻한 일상을 함께 합니다
      </span>`,
  },
  {
    title: "방문요양 서비스",
    contents: `<strong>재가 센터</strong>
      <p>
        방문요양, 주야간보호, 방문간호, 방문목욕, 단기보호, <br />
        복지용구 시설들을 편하게 찾아보세요
      </p>
      <span>
        기관평가와 함께 <br />
        집근처 센터까지 확인할 수 있어요
      </span>`,
  },
  {
    title: "비대면 결제",
    contents: `<strong>병원비·간병비 비대면 결제</strong>
      <p>
        병원에 갈 필요 없이 병원비 따로 간병비 따로! <br />
        이제 앱으로 편하게 결제하세요
      </p>
      <span>
        *의료비 결제 서비스는 <br />
        추후 업데이트 될 예정입니다
      </span>`,
  },
  {
    title: "의료용품 스토어",
    contents: `<strong>의료용품 스토어</strong>
      <p>
        매번 구매해야 하는 의료용품, <br />
        합리적인 가격에 간편하게 구매하세요
      </p>
      <span>
        원하는 제품을 손쉽게 <br />
        검색할 수 있습니다
      </span>`,
  },
  {
    title: "병원·약국 찾기",
    contents: `<strong>병원·약국</strong>
      <p>
        근처 병원·약국의 <br />
        영업시간, 진료과목을 확인하세요
      </p>
      <span>
        케어네이션 맵으로 병원 찾기가 <br />
        더욱 편리해졌습니다
      </span>`,
  },
  {
    title: "복약 관리",
    contents: `<strong>복약 정보 검색/알림 관리</strong>
      <p>
        잊기 쉬운 약 복용 방법 <br />
        대신 챙겨드릴게요
      </p>
      <span>
        처방받은 약, 매일 챙겨 먹어야 하는 영양제 <br />
        모두 깜빡하지 않도록 복약 알림을 설정하세요
      </span>`,
  },
  {
    title: "요양원 찾기",
    contents: `<strong>요양 시설</strong>
      <p>
        우리 부모님 더욱 편안하게 모실 수 있도록 <br />
        원하는 등급의 시설을 찾아보세요
      </p>
      <span>
        요양원, 노인공동생활 가정 등을 <br />
        빠르게 검색할 수 있어요
      </span>`,
  },
  {
    title: "상조·장례",
    contents: `<strong>상조·장례</strong>
      <p>
        장례 시설 찾기, 부고장 작성 및 관리,
        <br />
        상조가입 등이 가능해요
      </p>
      <span>
        갑작스럽게 준비해야 하는 장례 여정을
        <br />
        케어네이션이 함께 합니다
      </span>`,
  },
];