<template>
  <main>
    <section class="careCalc">
      <div class="basicWrap clear">
        <div class="subMain__tit">
          <h2>
            한눈에 알아보는<br />
            예상 간병비
          </h2>
          <p>
            진단명과 환자 상태를 입력해 간편하게<br />
            예상 간병비를 계산할 수 있습니다.
          </p>
        </div>

        <article class="careCalc__search" v-if="precalcStep == 0">
          <h3>진단명이 무엇인가요?</h3>
          <div class="careCalc__search--result">
            <div class="careCalc__search--input">
              <input type="text" id="precalcTxt01" :value="searchKeyword" @input="updateSearchKeyword" placeholder="환자 진단명을 입력해 주세요." />
            </div>
            <div class="noneBox" v-if="noneItem">
              <button type="button" @click="pickDis('진단명이 없습니다.')">진단명이 없습니다.</button>
            </div>
            <ul v-if="!noneItem">
              <li v-for="item in filteredItems" :key="item.diseaseName" @click="pickDis(item.diseaseName)">
                {{ item.diseaseName }}
              </li>
            </ul>
          </div>
        </article>
        <article class="careCalc__que" v-if="precalcStep == 1">
          <h3>진단명이 무엇인가요?</h3>
          <div>
            <div class="careCalc__que--dise">
              <p>{{ pickedDis }}</p>
              <button type="button" class="deleteBtn" @click="pickedReset()">삭제</button>
            </div>
            <div class="careCalc__que--select">
              <ul>
                <li class="clear">
                  <div class="fadeBox">
                    <h4>석션 또는 피딩이 필요하신가요?</h4>
                    <div class="clear">
                      <input type="radio" name="addRadLine01" id="addRad01" v-model="addRadLine01" :value="false" />
                      <label for="addRad01">아니요</label>
                      <input type="radio" name="addRadLine01" id="addRad02" v-model="addRadLine01" :value="true" />
                      <label for="addRad02">예</label>
                    </div>
                  </div>
                </li>
                <li class="clear">
                  <div class="fadeBox">
                    <h4>치매·섬망·수면장애 중 해당되는 질환이 있으신가요?</h4>
                    <div class="clear">
                      <input type="radio" name="addRadLine02" id="addRad03" v-model="addRadLine02" :value="false" />
                      <label for="addRad03">아니요</label>
                      <input type="radio" name="addRadLine02" id="addRad04" v-model="addRadLine02" :value="true" />
                      <label for="addRad04">예</label>
                    </div>
                  </div>
                </li>
                <li class="clear">
                  <div class="fadeBox">
                    <h4>마비 또는 욕창이 있으신가요?</h4>
                    <div class="clear">
                      <input type="radio" name="addRadLine03" id="addRad05" v-model="addRadLine03" :value="false" />
                      <label for="addRad05">아니요</label>
                      <input type="radio" name="addRadLine03" id="addRad06" v-model="addRadLine03" :value="true" />
                      <label for="addRad06">예</label>
                    </div>
                  </div>
                </li>
                <li class="clear">
                  <div class="fadeBox">
                    <h4>전염성 질환이 있으신가요?</h4>
                    <div class="clear">
                      <input type="radio" name="addRadLine04" id="addRad07" v-model="addRadLine04" :value="false" />
                      <label for="addRad07">아니요</label>
                      <input type="radio" name="addRadLine04" id="addRad08" v-model="addRadLine04" :value="true" />
                      <label for="addRad08">예</label>
                    </div>
                  </div>
                </li>
              </ul>
              <div class="careCalcBtn">
                <!-- input이 모두 체크되면 button 에 solidBtn 클래스 추가 -->
                <button type="submit" @click="chkRadio(), resultChargeCalc()" :class="allChk ? 'solidBtn' : ''" disabled ref="btnGoCalc">
                  예상 간병비 계산하기
                </button>
              </div>
            </div>
          </div>
        </article>
        <article class="careCalc__chart" v-if="precalcStep == 2">
          <h3 class="a11y-hidden">예상 간병비 결과</h3>
          <div class="careCalc__chart--diseInfo clear" :class="openResult ? 'on' : ''">
            <h4>진단명</h4>
            <p>{{ pickedDis }}</p>
            <button type="button" @click="openResult = !openResult">
              {{ openResult ? '접기' : '펼치기' }}
            </button>
            <ul>
              <li class="clear">
                <strong>석션·피딩</strong>
                <span>{{ addRadLine01 ? '예' : '아니오' }}</span>
              </li>
              <li class="clear">
                <strong>치매·섬망·수면장애</strong>
                <span>{{ addRadLine02 ? '예' : '아니오' }}</span>
              </li>
              <li class="clear">
                <strong>마비·욕창</strong>
                <span>{{ addRadLine03 ? '예' : '아니오' }}</span>
              </li>
              <li class="clear">
                <strong>전염성 질환</strong>
                <span>{{ addRadLine04 ? '예' : '아니오' }}</span>
              </li>
            </ul>
          </div>
          <div class="careCalc__chart--graph clear">
            <div>
              <h4>하루 예상 간병비</h4>
              <span><VueCountUp :endVal="resultChar.average" />원</span>
              <small>( 내국인 식대 포함 기준 )</small>
            </div>
            <div class="graph">
              <PreCalcChart :resultChar="resultChar" />
              <p class="graph__label">
                <span class="amount">최소 금액</span>
                <span class="amount">평균 금액</span>
                <span class="amount">최대 금액</span>
              </p>
            </div>
          </div>
          <div class="careCalc__chart--caution clear">
            <span>* 2024.06 기준</span>
            <p>* 위 정보는 임시로 계산된 예상 간병비로 <b>실제 간병비와는 차이</b>가 있을 수 있습니다.</p>
          </div>
          <div class="careCalc__chart--dataInfo clear">
            <div class="average clear">
              <h4>평균 간병 기간</h4>
              <div>
                <p>
                  <span>{{ period }}</span
                  >일
                </p>
              </div>
            </div>
            <div class="ratio">
              <h4>간병 장소 비율</h4>
              <div>
                <h5>병원</h5>
                <p>
                  <span>{{ siteRatio.hospital }}</span
                  >%
                </p>
              </div>
              <div>
                <h5>집</h5>
                <p>
                  <span>{{ siteRatio.home }}</span
                  >%
                </p>
              </div>
            </div>
          </div>
          <div class="careCalcBtn clear">
            <button
              type="button"
              class="solidBtn"
              onclick="window.open('https://v94bh.app.link/7WmTNXYN0Jb')"
              v-if="this.storeInfo == 'window' || this.storeInfo == 'android'"
            >
              간병 공고 등록하러 가기
            </button>
            <button
              type="button"
              class="solidBtn"
              onclick="window.open('https://v94bh.app.link/ADD9pPbO0Jb')"
              v-if="this.storeInfo == 'mac' || this.storeInfo == 'ios' || this.storeInfo == 'ipad'"
            >
              간병 공고 등록하러 가기
            </button>
            <button type="button" class="retry" @click="pickedReset()">다시하기</button>
          </div>
        </article>
      </div>
    </section>
  </main>
</template>

<script>
import * as Utils from '../../constants/utils';
import diseaseData from '@/assets/js/disease';
import Hangul from 'hangul-js';
import VueCountUp from 'vue-countup-v2';
import PreCalcChart from './PrecalcChart.vue';
import carePrice from '@/assets/js/carePrice.js';
export default {
  components: {
    PreCalcChart: PreCalcChart,
    VueCountUp: VueCountUp,
  },
  data() {
    return {
      storeInfo: Utils.storeType(), //## os 구분
      precalcStep: 0,
      diseaseList: diseaseData,
      searchKeyword: '',
      pickedDis: '',
      popupOn: false,
      addRadLine01: null,
      addRadLine02: null,
      addRadLine03: null,
      addRadLine04: null,
      allChk: false,
      openResult: true,
      carePrice: carePrice,
      resultCharType: null,
      resultChar: { average: 0, max: 0, min: 0 },
      period: 0,
      siteRatio: { hospital: 0, home: 0 },
      noneItem: false,
    };
  },
  methods: {
    updateSearchKeyword(event) {
      this.searchKeyword = event.target.value;
    },
    pickDis(picked) {
      this.pickedDis = picked;
      this.precalcStep = 1;
      const matchedData = diseaseData.find((data) => data.diseaseName === picked);
      if (matchedData) {
        const carePeriod = matchedData.carePeriod;
        this.period = carePeriod;
        const roundedCarePlace = {
          home: Math.round(matchedData.carePlace.home),
          hospital: Math.round(matchedData.carePlace.hospital),
        };
        this.siteRatio = roundedCarePlace;
      }
    },
    chkRadio() {
      if (this.addRadLine01 !== null && this.addRadLine02 !== null && this.addRadLine03 !== null && this.addRadLine04 !== null) {
        this.precalcStep = 2;
      } else {
        this.popupOn = true;
      }
    },
    popupClose() {
      this.popupOn = false;
    },
    pickedReset() {
      this.searchKeyword = '';
      this.precalcStep = 0;
      this.addRadLine01 = null;
      this.addRadLine02 = null;
      this.addRadLine03 = null;
      this.addRadLine04 = null;
      this.allChk = false;
    },
    resultChargeCalc() {
      const pickResult = [
        this.addRadLine01 === true ? '1' : '0',
        this.addRadLine02 === true ? '1' : '0',
        this.addRadLine03 === true ? '1' : '0',
        this.addRadLine04 === true ? '1' : '0',
      ];
      this.resultCalc(pickResult);
    },
    resultCalc(result) {
      const patternMap = {
        '0000': { min: 110000, average: 120000, max: 149000 },
        '0001': { min: 123000, average: 133000, max: 162000 },
        '0010': { min: 112000, average: 125000, max: 163000 },
        '0011': { min: 125000, average: 138000, max: 176000 },
        '0100': { min: 113000, average: 124000, max: 159000 },
        '0101': { min: 126000, average: 138000, max: 172000 },
        '0110': { min: 115000, average: 130000, max: 173000 },
        '0111': { min: 128000, average: 142000, max: 186000 },
        1000: { min: 120000, average: 130000, max: 159000 },
        1001: { min: 133000, average: 143000, max: 172000 },
        1010: { min: 122000, average: 135000, max: 173000 },
        1011: { min: 135000, average: 148000, max: 186000 },
        1100: { min: 123000, average: 134000, max: 169000 },
        1101: { min: 136000, average: 148000, max: 182000 },
        1110: { min: 125000, average: 140000, max: 183000 },
        1111: { min: 138000, average: 152000, max: 196000 },
      };

      const pattern = result.join('');
      this.resultChar = patternMap[pattern] || null;
    },
  },
  computed: {
    filteredItems() {
      if (!this.searchKeyword) return this.diseaseList;

      const searchKeywords = Array.from(this.searchKeyword.toLowerCase()).map((char) => {
        const [chosung, jungsung, jongsung] = Hangul.disassemble(char);
        return {
          chosung: chosung || '',
          jungsung: jungsung || '',
          jongsung: jongsung || '',
        };
      });

      const filteredList = this.diseaseList.filter((item) => {
        const itemKeywords = Array.from(item.diseaseName.toLowerCase()).map((char) => {
          const [chosung, jungsung, jongsung] = Hangul.disassemble(char);
          return {
            chosung: chosung || '',
            jungsung: jungsung || '',
            jongsung: jongsung || '',
          };
        });

        return searchKeywords.every((keyword) => {
          return itemKeywords.some((itemKeyword) => {
            return Object.entries(keyword).every(([key, value]) => {
              return value === '' || itemKeyword[key] === value;
            });
          });
        });
      });

      filteredList.sort((a, b) => {
        const aIndex = a.diseaseName.toLowerCase().indexOf(this.searchKeyword.toLowerCase());
        const bIndex = b.diseaseName.toLowerCase().indexOf(this.searchKeyword.toLowerCase());

        if (aIndex !== bIndex) {
          return aIndex - bIndex;
        } else {
          return a.diseaseName.localeCompare(b.diseaseName);
        }
      });

      return filteredList;
    },
  },
  updated() {
    if (this.precalcStep == 1) {
      if (this.addRadLine01 !== null && this.addRadLine02 !== null && this.addRadLine03 !== null && this.addRadLine04 !== null) {
        this.$refs.btnGoCalc.disabled = false;
        this.allChk = true;
      }
    }
    if (this.filteredItems.length === 0) {
      this.noneItem = true;
    } else {
      this.noneItem = false;
    }
  },
};
</script>
