export default [
  {
    title:"간병인 직접 선택"
  },
  {
    title:"간병비 카드결제"
  },
  {
    title:"간병인배상책임보험"
  },
  {
    title:"간병인 입찰제"
  }
]
