import moment from 'moment/moment';

/**
 * Empty Check
 * ——————————————————————————————————————————————————————————
 *
 * @param value : Object Value
 */
export function isEmpty(value) {
    if (typeof value === "undefined" || value === null || value === "") {
        return true
    } else {
        return false
    }
}

/**
 * Number Regex
 * ---------------------------------------------------------------------------------------------------------------------
 *
 * @param value : Object Value
 */
export function isNumber(value) {
    let regExp = /^[0-9]*$/;
    return regExp.test(value);
}
/**
 * Password Pattern Regex
 * ---------------------------------------------------------------------------------------------------------------------
 *
 * @param value : 입력한 값
 * @param phone : 핸드폰번호
 */
export function passwordCheck(value) {
    if (isEmpty(value)) {
        return true;
    }
    let flag;
    // eslint-disable-next-line
    let regExp01 = /^(?=.*?[a-zA-Z])(?=.*?[0-9])(?=.*?[!?@#$%^&*():;+=~{}<>\[\]|,.\/`]).{8,}$/; // 8자리 이상, 영문 포함, 숫자 포함, 특수문자 포함 정규식
    flag = regExp01.test(value);

    if (!flag) {
    // eslint-disable-next-line
        let regExp02 = /^(?=.*?[a-zA-Z])(?=.*?[0-9]).{10,}$/; // 10자리 이상, 영문 포함, 숫자 포함 정규식
        flag = regExp02.test(value);
    }

    if (!flag) {
    // eslint-disable-next-line
        let regExp03 = /^(?=.*?[a-zA-Z])(?=.*?[!?@#$%^&*():;+=~{}<>\[\]|,.\/`]).{10,}$/; // 10자리 이상, 영문 포함, 특수문자 포함 정규식
        flag = regExp03.test(value);
    }

    if (flag) {
      flag = compareChar(value); // 숫자 연속 사용 체크
    }
    return flag;
}

/**
 * 숫자 연속 사용 체크
 * ---------------------------------------------------------------------------------------------------------------------
 *
 * @param value : 입력한 값
 */
export function compareChar(value) {
    let cnt = 0;
    for (let i = 0; i < value.length - 1; i++) {
        if (isNumber(value[i])) {
            let char01 = value.charCodeAt(i);
            let char02 = value.charCodeAt(i + 1);
            if (char01 - char02 === 1) {
                cnt++;
            } else if (char01 - char02 === -1) {
                cnt--;
            } else {
                cnt = 0;
            }
            if (Math.abs(cnt) === 2) {
                return false;
            }
        } else {
            cnt = 0;
        }
    }

    return true;
}
/**
* OS Type Check : Tablet / Mobile / Web
* ——————————————————————————————————————————————————————————
*/
export function osType() {
    let os = '';
        if (navigator.userAgent.match(/Tablet|iPad/i)) {
            os = "tablet";
        } else if (navigator.userAgent.match(/Mobile|Windows Phone|Lumia|Android|webOS|iPhone|iPod|Blackberry|PlayBook|BB10|Opera Mini|\bCrMo\/|Opera Mobi/i)) {
            os = "mobile";
        } else {
            os = "web";
        }

    return os;  
}

export function storeType() {
    var userOs = navigator.userAgent.replace(/ /g, '').toLowerCase();
    let os = '';
        if( userOs.match(/macintosh/i) == "macintosh") {
            os = 'mac'
        }else if(userOs.match(/window/i) == "window") {
            os = 'window'
        }else if(userOs.match(/android/i) == "android") {
            os = 'android'
        }else if(userOs.match(/iphone/i) == "iphone") {
            os = 'ios'
        }else if(userOs.match(/ipad/i) == "ipad") {
            os = 'ipad'
        }
    return os;
}
/**
 * 콤마찍기
 * ---------------------------------------------------------------------------------------------------------------------
 *
 * @param number : Value
 */
    export function numberWithCommas(number) {
    try {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } catch (e) {
        return "0";
    }
}

/**
 * Date Converting
 * ---------------------------------------------------------------------------------------------------------------------
 *
 * @param momentDate : Date Value
 */
export function convertDateToString(momentDate) {
    return moment(momentDate).format('YYYY-MM-DD');
}

/**
 * Email Pattern Regex
 * ---------------------------------------------------------------------------------------------------------------------
 *
 * @param value : Object Value
 */
export function emailCheck(value) {
    let regExp = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
    return regExp.test(value);
}