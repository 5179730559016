<template>
  <section class="terms">
    <div class="loadingBg" v-if="loadingDone">
      <div class="loadingWrap">
        <span class="loading"></span>
      </div>
    </div>
    <div class="basicWrap">
      <div class="terms__tit clear">
        <div>
          <span>{{ this.pickStat == 1 ? "[현행] " : "[이전자료] " }}</span>
          <h2>{{ title }}</h2>
          <!-- <p>
            해당 문서의 효력은 YYYY.MM.DD에 종료 될 예정입니다.<br />
            시행 예정인 문서의 열람을 원하신다면, 문서함에서 선택해주세요.
          </p> -->
        </div>
        <div class="terms__tit--box">
          <h3>문서함</h3>
          <p>원하는 문서를 선택하여 열람할 수 있어요.</p>
          <select v-model="pickedPosition" @change="clauseListDetailApi">
            <option v-for="(termsItem, index) in termsList" :key="index" :value="termsItem.id">
              {{ formatDate(termsItem.enforcement_at, index) }}
            </option>
          </select>
        </div>
      </div>
      <article class="terms__txt">
        <div v-html="pickTxt"></div>
      </article>
    </div>
  </section>
</template>

<script>
// import * as Utils from "../../constants/utils";
import Api from "../../api/index";
import moment from "moment";
export default {
  data() {
    return {
      loadingDone: true,
      typeOfTerms: "",
      title: "",
      termsList: [],
      curPosition: 0,
      pickedPosition: 0,
      selPosition: 0,
      pickTxt: "",
      pickStat: 1, // 선택된 상태
      msg: "", //오류 메시지
      defaultMsg: "일시적인 오류가 발생하였습니다.\n잠시 후 다시 시도해주세요.",
    };
  },
  watch: {
    "$route.name": function () {
      this.typeChange();
    },
  },
  methods: {
    typeChange() {
      switch (this.$route.name) {
        case "protector":
          //## route name이 보호자 이용약관(protector) 이라면
          this.typeOfTerms = "protector";
          this.title = "보호자 이용약관";
          break;
        case "protectorPrivacy":
          //## route name이 보호자 개인정보처리방침(protectorPrivacy) 이라면
          this.typeOfTerms = "protectorPrivacy";
          this.title = "보호자 개인정보처리방침";
          break;
        case "caregiver":
          //## route name이 간병인 이용약관(caregiver) 이라면
          this.typeOfTerms = "caregiver";
          this.title = "간병인 이용약관";
          break;
        case "caregiverPrivacy":
          //## route name이 간병인 개인정보처리방침(caregiverPrivacy) 이라면
          this.typeOfTerms = "caregiverPrivacy";
          this.title = "간병인 개인정보처리방침";
          break;
        case "location":
          //## route name이 위치기반서비스 이용약관(location) 이라면
          this.typeOfTerms = "location";
          this.title = "위치기반 서비스 이용약관";
          break;
      }
      this.termsListLoad();
    },
    termsListLoad() {
      try {
        let api;
        switch (this.typeOfTerms) {
          case "protector":
            //## typeOfTerms가 보호자 이용약관(protector) 이라면
            api = Api.getTermsProtectorVersion();
            break;
          case "protectorPrivacy":
            //## typeOfTerms가 보호자 개인정보처리방침(protectorPrivacy) 이라면
            api = Api.getPolicyProtectorVersion();
            break;
          case "caregiver":
            //## typeOfTerms가 간병인 이용약관(caregiver) 이라면
            api = Api.getTermsCaregiverVersion();
            break;
          case "caregiverPrivacy":
            //## typeOfTerms가 간병인 개인정보처리방침(caregiverPrivacy) 이라면
            api = Api.getPolicyCaregiverVersion();
            break;
          case "location":
            //## typeOfTerms가 위치기반서비스 이용약관(location) 이라면
            api = Api.getTermsLocationService();
            break;
        }
        api
          ?.then((response) => {
            if (response.status === 200) {
              let data = response.data;
              if (data.code === 200) {
                this.termsList = data.data;
                this.selPosition = this.termsList[0].id;
                this.pickedPosition = this.selPosition;
                if (this.pickTxt == "" || this.pickTxt == this.pickTxt) {
                  this.clauseListDetailApi();
                }
              } else {
                alert(data.message);
              }
            } else {
              alert(this.defaultMsg);
            }
          })
          .catch(() => {
            alert(this.defaultMsg);
          });
      } catch (e) {
        alert(this.defaultMsg);
      }
    },
    clauseListDetailApi() {
      try {
        let api;
        if (this.typeOfTerms === "protector" || this.typeOfTerms === "caregiver") {
          api = Api.getTerms(this.pickedPosition);
        } else {
          api = Api.getPolicy(this.pickedPosition);
        }
        api
          .then((response) => {
            if (response.status === 200) {
              let data = response.data;
              if (data.code === 200) {
                this.pickTxt = data.data.content;
                if (this.pickedPosition !== this.termsList[0].id) {
                  this.pickStat = 0;
                } else {
                  this.pickStat = 1;
                }
                this.loadingDone = false;
              } else {
                // let msg = Utils.isEmpty(data.message) ? this.defaultMsg : data.message;
                this.msg = data.message;
                alert(this.msg);
              }
            } else {
              alert(this.defaultMsg);
            }
          })
          .catch((err) => {
            console.log(err);
            alert(this.defaultMsg);
          });
      } catch (e) {
        alert(this.defaultMsg);
      }
    },
    formatDate(date, idx) {
      if (idx < this.curPosition) {
        return "[시행예정]" + moment(date).format("YYYY-MM-DD");
      } else if (idx === this.curPosition) {
        return "[현행]" + moment(date).format("YYYY-MM-DD");
      } else if (idx > this.curPosition) {
        return "[이전자료]" + moment(date).format("YYYY-MM-DD");
      }
    },
  },
  mounted() {
    this.typeChange();
  },
};
</script>
