export default [
  {
    result01: {
      average: 135000,
      max: 151000,
      min: 135000
    },
    result02: {
      average: 140000,
      max: 150000,
      min: 85000
    },
    result03: {  
      average: 150000,
      max: 160000,
      min: 123000
    },
    result04: {
      average: 133000,
      max: 145000,
      min: 95000
    },
    result05: {
      average: 168000,
      max: 180000,
      min: 130000
    },
    result06: {
      average: 135000,
      max: 150000,
      min: 100000
    },
    result07: {
      average: 133000,
      max: 150500,
      min: 130000
    },
    result08: {
      average: 130000,
      max: 140000,
      min: 90000
    },
    result09: {
      average: 150000,
      max: 158000,
      min: 135000
    },
    result10: {
      average: 125000,
      max: 135000,
      min: 93000
    },
    result11: {
      average: 140000,
      max: 145000,
      min: 125000
    },
    result12: {
      average: 120000,
      max: 135000,
      min: 80000
    },
    result13: {
      average: 130000,
      max: 147000,
      min: 90000
    },
    result14: {
      average: 120000,
      max: 135000,
      min: 80000
    },
    result15: {
      average: 140000,
      max: 150000,
      min: 100000
    },
    result16: {
      average: 105000,
      max: 125000,
      min: 80000
    },
  }
]