import "core-js/stable/promise";
import "core-js/stable";
import Vue from "vue";
import router from "./router";
import AOS from "aos";
import App from "./App.vue";

import "aos/dist/aos.css";
import "@/assets/css/reset.css";
import "@/assets/css/common.css";
import "@/assets/css/pc.css";
import "@/assets/css/mobile.css";

Vue.config.productionTip = false;

new Vue({
  created() {
    AOS.init();
  },
  router,
  render: (h) => h(App),
}).$mount("#app");
