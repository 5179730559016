export default [
  {
    title:"간병일감 직접 선택"
  },
  {
    title:"간병비 직접 제시"
  },
  {
    title:"간병비 바로 지급"
  },
  {
    title:"일한날만 지불하는 보험료"
  }
]
