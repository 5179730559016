<template>
  <main>
    <section class="hospital">
      <article class="subMain mobileBg">
        <div class="basicWrap clear">
          <div class="subMain__tit">
            <h2>
              간병비·병원비를 손쉽게<br />
              청구할 수 있는 비대면 결제!
            </h2>
            <p>
              케어네이션에서 제공하는<br />
              관리자 페이지를 통해 비대면으로<br />
              결제를 요청할 수 있습니다.
            </p>
            <!-- <a :href="hosURL + '/login'" target="_blank">비대면 결제 서비스 이용하기</a> -->
            <a style="cursor:default">비대면 결제 서비스 이용하기</a>
          </div>
          <div class="subMain__img">
            <img src="@/assets/img/img_submain_03.png" alt="간병비·병원비를 손쉽게 청구할 수 있는 비대면 결제!" />
          </div>
        </div>
      </article>  
      <article class="hospital__why">
        <div class="basicWrap">
          <div class="subMain__tit">
            <h2>왜 <span>케어네이션</span>이어야 할까요?</h2>
            <p>
              병원의 고민을 함께 생각하고<br />
              해결 방안을 제시합니다.
            </p>
          </div>
          <ul class="comShadowBox">
            <li>
              <h3>초기 구축 비용 ZERO</h3>
              <p>
                바로 비대면 결제 관리 시스템을<br />
                이용할 수 있습니다.
              </p>
            </li>
            <li>
              <h3>운영 업무 간소화</h3>
              <p>
                미납 결제 내역을 쉽게<br />
                파악할 수 있습니다.
              </p>
            </li>
            <li>
              <h3>병원 이미지 개선</h3>
              <p>
                카드 및 할부 결제로<br />
                보호자의 부담을 덜어줄 수 있습니다.
              </p>
            </li>
          </ul>
        </div>
      </article>
      <article class="hospital__bgInfo">
        <div class="basicWrap">
          <h2 class="a11y-hidden"></h2>
          <figure>
            <img src="@/assets/img/img_notebook.png" alt="" />
          </figure>
          <p>
            관리자 페이지에서 환자 정보와 정산내역을 확인 후,<br />
            문자 보내기를 통해 <b>구매자에게 결제를 요청</b>할 수 있습니다.<br />
            문자를 받은 보호자는 케어네이션 앱으로<br />
            <b>간병비·병원비를 결제</b>할 수 있습니다.
          </p>
        </div>
      </article>
      <article class="hospital__paySer">
        <div class="basicWrap">
          <div class="subMain__tit">
            <h2>주머니 속<br />
              병원 수납 창구
            </h2>
            <p>
              쉽고 간단한 비대면 결제 서비스로<br />
              보호자에게 더 나은 서비스를 제공하세요.
            </p>
            <small>*보호자 결제 화면</small>
          </div>
          <div class="scrolling">
            <span></span>
            <span></span>
            <span></span>
          </div>
          <div class="mobile induceScrolling"><span></span><span></span><span></span></div>
          <slick class="hospital__paySer--list clear" :options="slickOptions01">
            <div class="paystep step01">
              <h3>병원 방문 없이 카드결제로!</h3>
              <p>
                비대면 결제로 편리하게<br />
                간병비·병원비 결제가 가능합니다.
              </p>
            </div>
            <div class="paystep step02">
              <h3>나눠 결제하는 분할 납부</h3>
              <p>
                가족끼리 분담해서<br />
                결제할 수 있습니다.
              </p>
            </div>
            <div class="paystep step03">
              <h3>빠르고 안전한 결제 시스템</h3>
              <p>
                금융감독원의 허가를 받은<br />
                PG 서비스로 안전합니다.
              </p>
            </div>
          </slick>
        </div>
      </article>
      <article class="hospital__payStep">
        <div class="basicWrap">
          <div class="subMain__tit">
            <h2>비대면 결제 진행 절차</h2>
            <p>
              <b>간병비 대리 결제에 대한 부가세 문제</b>를<br />
              케어네이션을 통해 덜어낼 수 있습니다.
            </p>
          </div>
          <div class="hospital__payStep--img">
            <figure></figure>
          </div>
        </div>
      </article>
      <article class="matchingSer">
        <div class="basicWrap">
          <h2>지금 시작해보세요!</h2>
          <ul class="clear">
            <li>
              <!-- <a :href="hosURL + '/register'" target="_blank">
                <h3>병원회원 가입하기</h3>
                <p>결제가 쉬워집니다.</p>
                <button type="button">바로가기</button>
              </a> -->
              <a style="cursor:default">
                <h3>병원회원 가입하기</h3>
                <p>결제가 쉬워집니다.</p>
                <button type="button">바로가기</button>
              </a>
            </li>
            <li>
              <router-link to="/customer">
                <h3>상담 문의 남기기</h3>
                <p>궁금하신 점을 남겨보세요.</p>
                <button type="button">바로가기</button>
              </router-link>
            </li>
          </ul>
          <span>* 병원회원 서비스는 <b>PC에서만 사용 가능</b>합니다.</span>
        </div>
      </article>
    </section>
  </main>
</template>

<script>
import Slick from 'vue-slick';
import 'slick-carousel/slick/slick.css';
export default {
  components: { Slick },
  data() {
    return {
      hosURL : process.env.VUE_APP_HOSPITAL_URL,
      slickOptions01 : {
        arrows : false,
        slidesToShow : 3,
        disabled : true,
        responsive : [
          {
            breakpoint: 1023,
            settings: {
              slidesToShow : 1,
              slidesToScroll: 1,
              dots : true,
              dotsClass: 'hospital__paySer--pager clear',
              appendDots: '.hospital__paySer .basicWrap',
              customPaging: function(slide,index) {
                return '<button type="button">' + index + '</button>';
              }
            }
          }
        ]
      }
    }
  }
}
</script>
<style>
  .hospital__paySer--pager .slick-active button {
    background-image: url(@/assets/img/btn_pager_ac.png);
  }
</style>