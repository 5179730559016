<template>
  <main class="">
    <h2 class="a11y-hidden">납부 정보 조회</h2>
    <section class="subBg payInfo">
      <div class="commonWrap">
        <h2 class="sectionTit">요금 납부 정보 조회</h2>
        <p class="subBanPar">
          혼자 결제하기 부담되는 비용!<br />
          함께 결제를 진행해 보세요.
        </p>
      </div>
    </section>
    <section>
      <div class="commonWrap">
        <article class="payInfo__enter">
          <p>CARENATION PAYMENT</p>
          <h3>
            납부 정보 조회를 위한<br />
            정보를 입력해 주세요.
          </h3>
          <ul class="payDetail__box--form">
            <li class="clearfix">
              <label for="payName">환자명<span>(필수)</span> </label>
              <input type="text" id="payName" placeholder="환자명을 입력해 주세요." />
            </li>
            <li class="clearfix">
              <label for="payTel">연락처<span>(필수)</span> </label>
              <input
                type="text"
                id="payTel"
                placeholder="문자를 수신한 연락처를 입력해 주세요."
              />
            </li>
            <li class="clearfix">
              <label for="payNum">결제 번호<span>(필수)</span> </label>
              <input type="text" id="payNum" placeholder="결제 번호를 입력해 주세요." />
            </li>
          </ul>
          <div class="payDetail__box--btn">
            <button>조회하기</button>
          </div>
        </article>
      </div>
    </section>
  </main>
</template>

<script>
export default {

}
</script>

<style>

</style>