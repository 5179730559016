export default [
  {
      diseaseName: "CRE",
      carePlace: { home: 1.96, hospital: 98.04 },
      carePeriod: 6.27,
  },
  {
      diseaseName: "VRE",
      carePlace: { home: 2.33, hospital: 97.67 },
      carePeriod: 8.88,
  },
  {
      diseaseName: "간경변",
      carePlace: { home: 6.9, hospital: 93.1 },
      carePeriod: 6.03,
  },
  {
      diseaseName: "간경화",
      carePlace: { home: 5.44, hospital: 94.56 },
      carePeriod: 7.61,
  },
  {
      diseaseName: "간농양",
      carePlace: { home: 0.0, hospital: 100.0 },
      carePeriod: 7.17,
  },
  {
      diseaseName: "간성혼수",
      carePlace: { home: 0.0, hospital: 100.0 },
      carePeriod: 6.14,
  },
  {
      diseaseName: "간암",
      carePlace: { home: 2.63, hospital: 97.37 },
      carePeriod: 6.55,
  },
  {
      diseaseName: "간염",
      carePlace: { home: 0.0, hospital: 100.0 },
      carePeriod: 5.33,
  },
  {
      diseaseName: "간이식",
      carePlace: { home: 0.85, hospital: 99.15 },
      carePeriod: 8.72,
  },
  {
      diseaseName: "간질",
      carePlace: { home: 4.35, hospital: 95.65 },
      carePeriod: 7.39,
  },
  {
      diseaseName: "갈비뼈골절",
      carePlace: { home: 9.76, hospital: 90.24 },
      carePeriod: 8.8,
  },
  {
      diseaseName: "감기",
      carePlace: { home: 3.45, hospital: 96.55 },
      carePeriod: 9.66,
  },
  {
      diseaseName: "감염",
      carePlace: { home: 2.87, hospital: 97.13 },
      carePeriod: 6.66,
  },
  {
      diseaseName: "갑상선암",
      carePlace: { home: 0.0, hospital: 100.0 },
      carePeriod: 2.68,
  },
  {
      diseaseName: "개두술",
      carePlace: { home: 5.0, hospital: 95.0 },
      carePeriod: 9.85,
  },
  {
      diseaseName: "거동불가",
      carePlace: { home: 18.56, hospital: 81.44 },
      carePeriod: 6.92,
  },
  {
      diseaseName: "거동불편",
      carePlace: { home: 48.08, hospital: 51.92 },
      carePeriod: 5.63,
  },
  {
      diseaseName: "검사",
      carePlace: { home: 3.77, hospital: 96.23 },
      carePeriod: 5.65,
  },
  {
      diseaseName: "결석",
      carePlace: { home: 1.41, hospital: 98.59 },
      carePeriod: 4.46,
  },
  {
      diseaseName: "결핵",
      carePlace: { home: 1.9, hospital: 98.1 },
      carePeriod: 8.16,
  },
  {
      diseaseName: "경련",
      carePlace: { home: 3.45, hospital: 96.55 },
      carePeriod: 5.72,
  },
  {
      diseaseName: "경막하출혈",
      carePlace: { home: 0.0, hospital: 100.0 },
      carePeriod: 11.43,
  },
  {
      diseaseName: "경추골절",
      carePlace: { home: 0.0, hospital: 100.0 },
      carePeriod: 7.85,
  },
  {
      diseaseName: "경추손상",
      carePlace: { home: 6.67, hospital: 93.33 },
      carePeriod: 8.8,
  },
  {
      diseaseName: "고관절골절",
      carePlace: { home: 13.54, hospital: 86.46 },
      carePeriod: 9.46,
  },
  {
      diseaseName: "고관절수술",
      carePlace: { home: 7.85, hospital: 92.15 },
      carePeriod: 7.67,
  },
  {
      diseaseName: "고열",
      carePlace: { home: 5.88, hospital: 94.12 },
      carePeriod: 5.29,
  },
  {
      diseaseName: "고혈압",
      carePlace: { home: 8.33, hospital: 91.67 },
      carePeriod: 7.7,
  },
  {
      diseaseName: "골반골절",
      carePlace: { home: 8.0, hospital: 92.0 },
      carePeriod: 8.14,
  },
  {
      diseaseName: "골수염",
      carePlace: { home: 0.0, hospital: 100.0 },
      carePeriod: 8.33,
  },
  {
      diseaseName: "골수종",
      carePlace: { home: 9.09, hospital: 90.91 },
      carePeriod: 10.2,
  },
  {
      diseaseName: "골절",
      carePlace: { home: 10.07, hospital: 89.93 },
      carePeriod: 8.13,
  },
  {
      diseaseName: "공여",
      carePlace: { home: 0.0, hospital: 100.0 },
      carePeriod: 3.18,
  },
  {
      diseaseName: "관상동맥",
      carePlace: { home: 12.5, hospital: 87.5 },
      carePeriod: 6.78,
  },
  {
      diseaseName: "관장",
      carePlace: { home: 16.67, hospital: 83.33 },
      carePeriod: 7.62,
  },
  {
      diseaseName: "관절염",
      carePlace: { home: 9.78, hospital: 90.22 },
      carePeriod: 5.97,
  },
  {
      diseaseName: "괴사",
      carePlace: { home: 3.75, hospital: 96.25 },
      carePeriod: 5.64,
  },
  {
      diseaseName: "교모세포종",
      carePlace: { home: 23.08, hospital: 76.92 },
      carePeriod: 9.5,
  },
  {
      diseaseName: "교정",
      carePlace: { home: 0.0, hospital: 100.0 },
      carePeriod: 5.25,
  },
  {
      diseaseName: "교통사고",
      carePlace: { home: 1.15, hospital: 98.85 },
      carePeriod: 7.85,
  },
  {
      diseaseName: "구강암",
      carePlace: { home: 2.17, hospital: 97.83 },
      carePeriod: 5.72,
  },
  {
      diseaseName: "구토",
      carePlace: { home: 4.55, hospital: 95.45 },
      carePeriod: 5.5,
  },
  {
      diseaseName: "궤양",
      carePlace: { home: 0.0, hospital: 100.0 },
      carePeriod: 7.33,
  },
  {
      diseaseName: "기능저하",
      carePlace: { home: 2.5, hospital: 97.5 },
      carePeriod: 6.92,
  },
  {
      diseaseName: "기력저하",
      carePlace: { home: 21.05, hospital: 78.95 },
      carePeriod: 5.63,
  },
  {
      diseaseName: "기형",
      carePlace: { home: 0.0, hospital: 100.0 },
      carePeriod: 3.36,
  },
  {
      diseaseName: "기흉",
      carePlace: { home: 0.0, hospital: 100.0 },
      carePeriod: 5.28,
  },
  {
      diseaseName: "길랑바레",
      carePlace: { home: 0.0, hospital: 100.0 },
      carePeriod: 11.35,
  },
  {
      diseaseName: "깁스",
      carePlace: { home: 29.55, hospital: 70.45 },
      carePeriod: 6.41,
  },
  {
      diseaseName: "낙상",
      carePlace: { home: 11.68, hospital: 88.32 },
      carePeriod: 6.95,
  },
  {
      diseaseName: "난소암",
      carePlace: { home: 13.2, hospital: 86.8 },
      carePeriod: 6.69,
  },
  {
      diseaseName: "난청",
      carePlace: { home: 6.25, hospital: 93.75 },
      carePeriod: 5.19,
  },
  {
      diseaseName: "낭종",
      carePlace: { home: 0.0, hospital: 100.0 },
      carePeriod: 2.28,
  },
  {
      diseaseName: "내과",
      carePlace: { home: 7.29, hospital: 92.71 },
      carePeriod: 7.34,
  },
  {
      diseaseName: "내시경",
      carePlace: { home: 3.8, hospital: 96.2 },
      carePeriod: 3.18,
  },
  {
      diseaseName: "노환",
      carePlace: { home: 42.86, hospital: 57.14 },
      carePeriod: 4.74,
  },
  {
      diseaseName: "농양",
      carePlace: { home: 0.0, hospital: 100.0 },
      carePeriod: 6.6,
  },
  {
      diseaseName: "뇌경색",
      carePlace: { home: 9.37, hospital: 90.63 },
      carePeriod: 7.62,
  },
  {
      diseaseName: "뇌내출혈",
      carePlace: { home: 0.0, hospital: 100.0 },
      carePeriod: 7.74,
  },
  {
      diseaseName: "뇌동맥류",
      carePlace: { home: 1.09, hospital: 98.91 },
      carePeriod: 3.93,
  },
  {
      diseaseName: "뇌병변",
      carePlace: { home: 53.33, hospital: 46.67 },
      carePeriod: 10.18,
  },
  {
      diseaseName: "뇌손상",
      carePlace: { home: 0.65, hospital: 99.35 },
      carePeriod: 8.78,
  },
  {
      diseaseName: "뇌수막염",
      carePlace: { home: 5.26, hospital: 94.74 },
      carePeriod: 6.79,
  },
  {
      diseaseName: "뇌수막종",
      carePlace: { home: 0.0, hospital: 100.0 },
      carePeriod: 5.62,
  },
  {
      diseaseName: "뇌염",
      carePlace: { home: 5.36, hospital: 94.64 },
      carePeriod: 7.0,
  },
  {
      diseaseName: "뇌전증",
      carePlace: { home: 2.63, hospital: 97.37 },
      carePeriod: 6.55,
  },
  {
      diseaseName: "뇌졸중",
      carePlace: { home: 19.61, hospital: 80.39 },
      carePeriod: 8.25,
  },
  {
      diseaseName: "뇌종양",
      carePlace: { home: 19.39, hospital: 80.61 },
      carePeriod: 8.42,
  },
  {
      diseaseName: "뇌진탕",
      carePlace: { home: 0.0, hospital: 100.0 },
      carePeriod: 6.29,
  },
  {
      diseaseName: "뇌출혈",
      carePlace: { home: 6.87, hospital: 93.13 },
      carePeriod: 9.58,
  },
  {
      diseaseName: "뇌하수체종양",
      carePlace: { home: 6.67, hospital: 93.33 },
      carePeriod: 3.07,
  },
  {
      diseaseName: "다리골절",
      carePlace: { home: 3.6, hospital: 96.4 },
      carePeriod: 8.15,
  },
  {
      diseaseName: "다발골수종",
      carePlace: { home: 0.0, hospital: 100.0 },
      carePeriod: 10.43,
  },
  {
      diseaseName: "다발성근육염",
      carePlace: { home: 0.0, hospital: 100.0 },
      carePeriod: 6.17,
  },
  {
      diseaseName: "담관암",
      carePlace: { home: 10.0, hospital: 90.0 },
      carePeriod: 7.08,
  },
  {
      diseaseName: "담낭암",
      carePlace: { home: 2.13, hospital: 97.87 },
      carePeriod: 5.26,
  },
  {
      diseaseName: "담낭염",
      carePlace: { home: 3.51, hospital: 96.49 },
      carePeriod: 5.25,
  },
  {
      diseaseName: "담도암",
      carePlace: { home: 7.23, hospital: 92.77 },
      carePeriod: 5.66,
  },
  {
      diseaseName: "담석",
      carePlace: { home: 2.63, hospital: 97.37 },
      carePeriod: 3.67,
  },
  {
      diseaseName: "당뇨",
      carePlace: { home: 20.91, hospital: 79.09 },
      carePeriod: 6.44,
  },
  {
      diseaseName: "당뇨합병증",
      carePlace: { home: 2.78, hospital: 97.22 },
      carePeriod: 9.25,
  },
  {
      diseaseName: "대동맥류",
      carePlace: { home: 0.0, hospital: 100.0 },
      carePeriod: 5.15,
  },
  {
      diseaseName: "대동맥박리",
      carePlace: { home: 0.0, hospital: 100.0 },
      carePeriod: 8.94,
  },
  {
      diseaseName: "대동맥판막",
      carePlace: { home: 0.0, hospital: 100.0 },
      carePeriod: 4.38,
  },
  {
      diseaseName: "대장암",
      carePlace: { home: 3.67, hospital: 96.33 },
      carePeriod: 5.14,
  },
  {
      diseaseName: "대퇴골두무혈성괴사",
      carePlace: { home: 0.0, hospital: 100.0 },
      carePeriod: 4.35,
  },
  {
      diseaseName: "대퇴부골절",
      carePlace: { home: 5.97, hospital: 94.03 },
      carePeriod: 9.63,
  },
  {
      diseaseName: "동맥류",
      carePlace: { home: 0.81, hospital: 99.19 },
      carePeriod: 4.23,
  },
  {
      diseaseName: "두경부암",
      carePlace: { home: 16.67, hospital: 83.33 },
      carePeriod: 6.89,
  },
  {
      diseaseName: "디스크",
      carePlace: { home: 3.91, hospital: 96.09 },
      carePeriod: 6.29,
  },
  {
      diseaseName: "디스크수술",
      carePlace: { home: 0.53, hospital: 99.47 },
      carePeriod: 6.15,
  },
  {
      diseaseName: "디스크파열",
      carePlace: { home: 0.0, hospital: 100.0 },
      carePeriod: 4.81,
  },
  {
      diseaseName: "루게릭",
      carePlace: { home: 45.74, hospital: 54.26 },
      carePeriod: 11.67,
  },
  {
      diseaseName: "루게릭병",
      carePlace: { home: 36.84, hospital: 63.16 },
      carePeriod: 7.21,
  },
  {
      diseaseName: "류마티스",
      carePlace: { home: 18.75, hospital: 81.25 },
      carePeriod: 5.38,
  },
  {
      diseaseName: "림프암",
      carePlace: { home: 0.0, hospital: 100.0 },
      carePeriod: 4.47,
  },
  {
      diseaseName: "림프종",
      carePlace: { home: 4.2, hospital: 95.8 },
      carePeriod: 6.3,
  },
  {
      diseaseName: "마비",
      carePlace: { home: 15.55, hospital: 84.45 },
      carePeriod: 9.54,
  },
  {
      diseaseName: "만성폐쇄성폐질환",
      carePlace: { home: 12.5, hospital: 87.5 },
      carePeriod: 9.62,
  },
  {
      diseaseName: "말기암",
      carePlace: { home: 21.31, hospital: 78.69 },
      carePeriod: 7.49,
  },
  {
      diseaseName: "망상",
      carePlace: { home: 0.0, hospital: 100.0 },
      carePeriod: 11.65,
  },
  {
      diseaseName: "모야모야",
      carePlace: { home: 4.35, hospital: 95.65 },
      carePeriod: 7.74,
  },
  {
      diseaseName: "목디스크",
      carePlace: { home: 3.41, hospital: 96.59 },
      carePeriod: 6.28,
  },
  {
      diseaseName: "무릎관절수술",
      carePlace: { home: 6.52, hospital: 93.48 },
      carePeriod: 7.24,
  },
  {
      diseaseName: "무릎수술",
      carePlace: { home: 0.84, hospital: 99.16 },
      carePeriod: 6.44,
  },
  {
      diseaseName: "무릎연골수술",
      carePlace: { home: 2.33, hospital: 97.67 },
      carePeriod: 5.56,
  },
  {
      diseaseName: "무릎인공관절수술",
      carePlace: { home: 3.26, hospital: 96.74 },
      carePeriod: 6.51,
  },
  {
      diseaseName: "무지외반증",
      carePlace: { home: 0.0, hospital: 100.0 },
      carePeriod: 3.75,
  },
  {
      diseaseName: "물리치료",
      carePlace: { home: 0.0, hospital: 100.0 },
      carePeriod: 7.52,
  },
  {
      diseaseName: "물혹",
      carePlace: { home: 0.0, hospital: 100.0 },
      carePeriod: 2.45,
  },
  {
      diseaseName: "발목골절",
      carePlace: { home: 9.3, hospital: 90.7 },
      carePeriod: 6.43,
  },
  {
      diseaseName: "발목수술",
      carePlace: { home: 0.0, hospital: 100.0 },
      carePeriod: 4.71,
  },
  {
      diseaseName: "방광암",
      carePlace: { home: 3.37, hospital: 96.63 },
      carePeriod: 4.48,
  },
  {
      diseaseName: "방광염",
      carePlace: { home: 0.0, hospital: 100.0 },
      carePeriod: 3.94,
  },
  {
      diseaseName: "백내장",
      carePlace: { home: 13.33, hospital: 86.67 },
      carePeriod: 1.93,
  },
  {
      diseaseName: "백혈병",
      carePlace: { home: 2.08, hospital: 97.92 },
      carePeriod: 7.04,
  },
  {
      diseaseName: "변비",
      carePlace: { home: 17.86, hospital: 82.14 },
      carePeriod: 8.5,
  },
  {
      diseaseName: "복강경",
      carePlace: { home: 2.76, hospital: 97.24 },
      carePeriod: 2.48,
  },
  {
      diseaseName: "복막염",
      carePlace: { home: 0.0, hospital: 100.0 },
      carePeriod: 4.0,
  },
  {
      diseaseName: "복수",
      carePlace: { home: 5.88, hospital: 94.12 },
      carePeriod: 5.04,
  },
  {
      diseaseName: "봉합",
      carePlace: { home: 0.0, hospital: 100.0 },
      carePeriod: 5.43,
  },
  {
      diseaseName: "부상",
      carePlace: { home: 10.71, hospital: 89.29 },
      carePeriod: 7.54,
  },
  {
      diseaseName: "부인과",
      carePlace: { home: 1.92, hospital: 98.08 },
      carePeriod: 2.54,
  },
  {
      diseaseName: "부작용",
      carePlace: { home: 3.77, hospital: 96.23 },
      carePeriod: 5.17,
  },
  {
      diseaseName: "부정맥",
      carePlace: { home: 1.61, hospital: 98.39 },
      carePeriod: 5.29,
  },
  {
      diseaseName: "부종",
      carePlace: { home: 0.93, hospital: 99.07 },
      carePeriod: 6.69,
  },
  {
      diseaseName: "분만",
      carePlace: { home: 13.13, hospital: 86.87 },
      carePeriod: 8.21,
  },
  {
      diseaseName: "비뇨기과",
      carePlace: { home: 7.41, hospital: 92.59 },
      carePeriod: 3.93,
  },
  {
      diseaseName: "빈혈",
      carePlace: { home: 10.53, hospital: 89.47 },
      carePeriod: 5.68,
  },
  {
      diseaseName: "사지마비",
      carePlace: { home: 4.55, hospital: 95.45 },
      carePeriod: 7.3,
  },
  {
      diseaseName: "상세불명",
      carePlace: { home: 0.0, hospital: 100.0 },
      carePeriod: 16.19,
  },
  {
      diseaseName: "상해",
      carePlace: { home: 0.0, hospital: 100.0 },
      carePeriod: 6.56,
  },
  {
      diseaseName: "색전술",
      carePlace: { home: 0.0, hospital: 100.0 },
      carePeriod: 3.42,
  },
  {
      diseaseName: "석션",
      carePlace: { home: 8.32, hospital: 91.68 },
      carePeriod: 9.73,
  },
  {
      diseaseName: "선종",
      carePlace: { home: 0.0, hospital: 100.0 },
      carePeriod: 4.45,
  },
  {
      diseaseName: "설사",
      carePlace: { home: 8.33, hospital: 91.67 },
      carePeriod: 8.19,
  },
  {
      diseaseName: "설암",
      carePlace: { home: 11.11, hospital: 88.89 },
      carePeriod: 7.93,
  },
  {
      diseaseName: "섬망",
      carePlace: { home: 8.33, hospital: 91.67 },
      carePeriod: 7.53,
  },
  {
      diseaseName: "소뇌위축",
      carePlace: { home: 0.0, hospital: 100.0 },
      carePeriod: 14.88,
  },
  {
      diseaseName: "소변줄",
      carePlace: { home: 6.71, hospital: 93.29 },
      carePeriod: 6.88,
  },
  {
      diseaseName: "손목골절",
      carePlace: { home: 13.04, hospital: 86.96 },
      carePeriod: 7.22,
  },
  {
      diseaseName: "손상",
      carePlace: { home: 1.81, hospital: 98.19 },
      carePeriod: 9.43,
  },
  {
      diseaseName: "쇠약",
      carePlace: { home: 7.69, hospital: 92.31 },
      carePeriod: 6.81,
  },
  {
      diseaseName: "쇼크",
      carePlace: { home: 0.0, hospital: 100.0 },
      carePeriod: 11.89,
  },
  {
      diseaseName: "수두증",
      carePlace: { home: 4.69, hospital: 95.31 },
      carePeriod: 5.89,
  },
  {
      diseaseName: "수면장애",
      carePlace: { home: 12.68, hospital: 87.32 },
      carePeriod: 8.63,
  },
  {
      diseaseName: "수술",
      carePlace: { home: 3.69, hospital: 96.31 },
      carePeriod: 5.71,
  },
  {
      diseaseName: "스텐트",
      carePlace: { home: 0.0, hospital: 100.0 },
      carePeriod: 4.3,
  },
  {
      diseaseName: "시각장애",
      carePlace: { home: 8.7, hospital: 91.3 },
      carePeriod: 6.13,
  },
  {
      diseaseName: "식도암",
      carePlace: { home: 1.0, hospital: 99.0 },
      carePeriod: 5.06,
  },
  {
      diseaseName: "식물인간",
      carePlace: { home: 0.0, hospital: 100.0 },
      carePeriod: 8.63,
  },
  {
      diseaseName: "신경손상",
      carePlace: { home: 0.0, hospital: 100.0 },
      carePeriod: 13.06,
  },
  {
      diseaseName: "신경외과",
      carePlace: { home: 0.0, hospital: 100.0 },
      carePeriod: 8.25,
  },
  {
      diseaseName: "신부전",
      carePlace: { home: 3.6, hospital: 96.4 },
      carePeriod: 6.2,
  },
  {
      diseaseName: "신우신염",
      carePlace: { home: 2.94, hospital: 97.06 },
      carePeriod: 5.94,
  },
  {
      diseaseName: "신장결석",
      carePlace: { home: 0.0, hospital: 100.0 },
      carePeriod: 3.55,
  },
  {
      diseaseName: "신장암",
      carePlace: { home: 4.04, hospital: 95.96 },
      carePeriod: 4.1,
  },
  {
      diseaseName: "신장이식",
      carePlace: { home: 0.0, hospital: 100.0 },
      carePeriod: 4.31,
  },
  {
      diseaseName: "신장질환",
      carePlace: { home: 0.0, hospital: 100.0 },
      carePeriod: 7.08,
  },
  {
      diseaseName: "실금",
      carePlace: { home: 16.67, hospital: 83.33 },
      carePeriod: 5.74,
  },
  {
      diseaseName: "심근경색",
      carePlace: { home: 2.78, hospital: 97.22 },
      carePeriod: 6.24,
  },
  {
      diseaseName: "심부전",
      carePlace: { home: 12.2, hospital: 87.8 },
      carePeriod: 6.87,
  },
  {
      diseaseName: "심장질환",
      carePlace: { home: 8.16, hospital: 91.84 },
      carePeriod: 6.84,
  },
  {
      diseaseName: "심장판막",
      carePlace: { home: 2.5, hospital: 97.5 },
      carePeriod: 5.66,
  },
  {
      diseaseName: "심정지",
      carePlace: { home: 0.0, hospital: 100.0 },
      carePeriod: 11.79,
  },
  {
      diseaseName: "심혈관질환",
      carePlace: { home: 0.0, hospital: 100.0 },
      carePeriod: 6.15,
  },
  {
      diseaseName: "십자인대파열",
      carePlace: { home: 0.0, hospital: 100.0 },
      carePeriod: 4.41,
  },
  {
      diseaseName: "안과",
      carePlace: { home: 4.17, hospital: 95.83 },
      carePeriod: 2.92,
  },
  {
      diseaseName: "알츠하이머",
      carePlace: { home: 16.42, hospital: 83.58 },
      carePeriod: 6.22,
  },
  {
      diseaseName: "양악",
      carePlace: { home: 0.0, hospital: 100.0 },
      carePeriod: 2.02,
  },
  {
      diseaseName: "어지러움증",
      carePlace: { home: 37.04, hospital: 62.96 },
      carePeriod: 4.22,
  },
  {
      diseaseName: "어지럼증",
      carePlace: { home: 6.67, hospital: 93.33 },
      carePeriod: 4.9,
  },
  {
      diseaseName: "언어장애",
      carePlace: { home: 6.45, hospital: 93.55 },
      carePeriod: 8.68,
  },
  {
      diseaseName: "염좌",
      carePlace: { home: 33.33, hospital: 66.67 },
      carePeriod: 5.93,
  },
  {
      diseaseName: "염증",
      carePlace: { home: 2.74, hospital: 97.26 },
      carePeriod: 6.43,
  },
  {
      diseaseName: "옴",
      carePlace: { home: 3.7, hospital: 96.3 },
      carePeriod: 6.87,
  },
  {
      diseaseName: "와상",
      carePlace: { home: 6.76, hospital: 93.24 },
      carePeriod: 7.74,
  },
  {
      diseaseName: "외상",
      carePlace: { home: 7.56, hospital: 92.44 },
      carePeriod: 9.0,
  },
  {
      diseaseName: "외상성뇌출혈",
      carePlace: { home: 6.12, hospital: 93.88 },
      carePeriod: 9.5,
  },
  {
      diseaseName: "요관암",
      carePlace: { home: 4.55, hospital: 95.45 },
      carePeriod: 5.18,
  },
  {
      diseaseName: "요로감염",
      carePlace: { home: 2.33, hospital: 97.67 },
      carePeriod: 5.53,
  },
  {
      diseaseName: "요로결석",
      carePlace: { home: 0.0, hospital: 100.0 },
      carePeriod: 3.62,
  },
  {
      diseaseName: "요실금",
      carePlace: { home: 0.0, hospital: 100.0 },
      carePeriod: 3.8,
  },
  {
      diseaseName: "요양",
      carePlace: { home: 25.9, hospital: 74.1 },
      carePeriod: 7.68,
  },
  {
      diseaseName: "욕창",
      carePlace: { home: 6.34, hospital: 93.66 },
      carePeriod: 8.86,
  },
  {
      diseaseName: "용종",
      carePlace: { home: 0.0, hospital: 100.0 },
      carePeriod: 3.04,
  },
  {
      diseaseName: "우울증",
      carePlace: { home: 11.24, hospital: 88.76 },
      carePeriod: 7.74,
  },
  {
      diseaseName: "위루관",
      carePlace: { home: 47.42, hospital: 52.58 },
      carePeriod: 9.42,
  },
  {
      diseaseName: "위암",
      carePlace: { home: 3.63, hospital: 96.37 },
      carePeriod: 4.79,
  },
  {
      diseaseName: "유방암",
      carePlace: { home: 2.11, hospital: 97.89 },
      carePeriod: 4.28,
  },
  {
      diseaseName: "육종",
      carePlace: { home: 7.14, hospital: 92.86 },
      carePeriod: 6.66,
  },
  {
      diseaseName: "의식불명",
      carePlace: { home: 0.0, hospital: 100.0 },
      carePeriod: 9.45,
  },
  {
      diseaseName: "이식",
      carePlace: { home: 2.16, hospital: 97.84 },
      carePeriod: 6.82,
  },
  {
      diseaseName: "이식수술",
      carePlace: { home: 0.0, hospital: 100.0 },
      carePeriod: 6.6,
  },
  {
      diseaseName: "이형성증",
      carePlace: { home: 5.88, hospital: 94.12 },
      carePeriod: 3.82,
  },
  {
      diseaseName: "인공관절",
      carePlace: { home: 2.57, hospital: 97.43 },
      carePeriod: 6.73,
  },
  {
      diseaseName: "인공관절수술",
      carePlace: { home: 3.11, hospital: 96.89 },
      carePeriod: 6.67,
  },
  {
      diseaseName: "인대파열",
      carePlace: { home: 2.63, hospital: 97.37 },
      carePeriod: 4.97,
  },
  {
      diseaseName: "인두암",
      carePlace: { home: 0.0, hospital: 100.0 },
      carePeriod: 7.7,
  },
  {
      diseaseName: "입원",
      carePlace: { home: 3.11, hospital: 96.89 },
      carePeriod: 6.27,
  },
  {
      diseaseName: "자궁경부암",
      carePlace: { home: 2.38, hospital: 97.62 },
      carePeriod: 4.55,
  },
  {
      diseaseName: "자궁근종",
      carePlace: { home: 0.94, hospital: 99.06 },
      carePeriod: 1.97,
  },
  {
      diseaseName: "자궁내막암",
      carePlace: { home: 0.0, hospital: 100.0 },
      carePeriod: 4.15,
  },
  {
      diseaseName: "자궁내막증",
      carePlace: { home: 0.0, hospital: 100.0 },
      carePeriod: 1.88,
  },
  {
      diseaseName: "자궁선근증",
      carePlace: { home: 0.0, hospital: 100.0 },
      carePeriod: 2.19,
  },
  {
      diseaseName: "자궁암",
      carePlace: { home: 0.0, hospital: 100.0 },
      carePeriod: 5.95,
  },
  {
      diseaseName: "자궁적출",
      carePlace: { home: 0.0, hospital: 100.0 },
      carePeriod: 2.61,
  },
  {
      diseaseName: "자상",
      carePlace: { home: 3.47, hospital: 96.53 },
      carePeriod: 7.59,
  },
  {
      diseaseName: "장루",
      carePlace: { home: 3.85, hospital: 96.15 },
      carePeriod: 7.06,
  },
  {
      diseaseName: "장애",
      carePlace: { home: 16.9, hospital: 83.1 },
      carePeriod: 7.62,
  },
  {
      diseaseName: "장염",
      carePlace: { home: 6.62, hospital: 93.38 },
      carePeriod: 6.24,
  },
  {
      diseaseName: "장폐색",
      carePlace: { home: 0.0, hospital: 100.0 },
      carePeriod: 5.83,
  },
  {
      diseaseName: "재건술",
      carePlace: { home: 0.0, hospital: 100.0 },
      carePeriod: 4.53,
  },
  {
      diseaseName: "재활",
      carePlace: { home: 6.85, hospital: 93.15 },
      carePeriod: 9.3,
  },
  {
      diseaseName: "저산소증",
      carePlace: { home: 0.0, hospital: 100.0 },
      carePeriod: 7.4,
  },
  {
      diseaseName: "저혈당",
      carePlace: { home: 0.0, hospital: 100.0 },
      carePeriod: 4.56,
  },
  {
      diseaseName: "저혈압",
      carePlace: { home: 8.33, hospital: 91.67 },
      carePeriod: 7.25,
  },
  {
      diseaseName: "적출",
      carePlace: { home: 3.08, hospital: 96.92 },
      carePeriod: 3.21,
  },
  {
      diseaseName: "전립선비대",
      carePlace: { home: 3.12, hospital: 96.88 },
      carePeriod: 4.12,
  },
  {
      diseaseName: "전립선암",
      carePlace: { home: 9.92, hospital: 90.08 },
      carePeriod: 6.65,
  },
  {
      diseaseName: "절개",
      carePlace: { home: 20.09, hospital: 79.91 },
      carePeriod: 8.25,
  },
  {
      diseaseName: "절단",
      carePlace: { home: 4.76, hospital: 95.24 },
      carePeriod: 7.75,
  },
  {
      diseaseName: "절제",
      carePlace: { home: 2.68, hospital: 97.32 },
      carePeriod: 4.01,
  },
  {
      diseaseName: "제거",
      carePlace: { home: 0.66, hospital: 99.34 },
      carePeriod: 4.37,
  },
  {
      diseaseName: "제왕절개",
      carePlace: { home: 1.3, hospital: 98.7 },
      carePeriod: 2.26,
  },
  {
      diseaseName: "조영술",
      carePlace: { home: 0.0, hospital: 100.0 },
      carePeriod: 3.3,
  },
  {
      diseaseName: "조직검사",
      carePlace: { home: 1.11, hospital: 98.89 },
      carePeriod: 3.21,
  },
  {
      diseaseName: "조현병",
      carePlace: { home: 0.0, hospital: 100.0 },
      carePeriod: 9.76,
  },
  {
      diseaseName: "종양",
      carePlace: { home: 13.52, hospital: 86.48 },
      carePeriod: 7.34,
  },
  {
      diseaseName: "중풍",
      carePlace: { home: 31.25, hospital: 68.75 },
      carePeriod: 3.06,
  },
  {
      diseaseName: "중환자",
      carePlace: { home: 0.0, hospital: 100.0 },
      carePeriod: 8.12,
  },
  {
      diseaseName: "지병",
      carePlace: { home: 5.71, hospital: 94.29 },
      carePeriod: 6.11,
  },
  {
      diseaseName: "지주막하출혈",
      carePlace: { home: 11.45, hospital: 88.55 },
      carePeriod: 8.92,
  },
  {
      diseaseName: "지체장애",
      carePlace: { home: 37.5, hospital: 62.5 },
      carePeriod: 7.75,
  },
  {
      diseaseName: "직장암",
      carePlace: { home: 2.68, hospital: 97.32 },
      carePeriod: 4.65,
  },
  {
      diseaseName: "척수손상",
      carePlace: { home: 0.0, hospital: 100.0 },
      carePeriod: 10.94,
  },
  {
      diseaseName: "척수염",
      carePlace: { home: 7.69, hospital: 92.31 },
      carePeriod: 10.06,
  },
  {
      diseaseName: "척수증",
      carePlace: { home: 0.0, hospital: 100.0 },
      carePeriod: 8.27,
  },
  {
      diseaseName: "척추골절",
      carePlace: { home: 9.43, hospital: 90.57 },
      carePeriod: 8.9,
  },
  {
      diseaseName: "척추관협착증",
      carePlace: { home: 7.27, hospital: 92.73 },
      carePeriod: 6.25,
  },
  {
      diseaseName: "척추손상",
      carePlace: { home: 6.67, hospital: 93.33 },
      carePeriod: 10.4,
  },
  {
      diseaseName: "척추수술",
      carePlace: { home: 4.8, hospital: 95.2 },
      carePeriod: 6.11,
  },
  {
      diseaseName: "척추압박골절",
      carePlace: { home: 4.17, hospital: 95.83 },
      carePeriod: 8.21,
  },
  {
      diseaseName: "척추전방전위증",
      carePlace: { home: 0.0, hospital: 100.0 },
      carePeriod: 6.66,
  },
  {
      diseaseName: "척추협착",
      carePlace: { home: 5.76, hospital: 94.24 },
      carePeriod: 6.68,
  },
  {
      diseaseName: "천공",
      carePlace: { home: 0.0, hospital: 100.0 },
      carePeriod: 7.2,
  },
  {
      diseaseName: "천포창",
      carePlace: { home: 0.0, hospital: 100.0 },
      carePeriod: 7.12,
  },
  {
      diseaseName: "출산",
      carePlace: { home: 0.0, hospital: 100.0 },
      carePeriod: 2.38,
  },
  {
      diseaseName: "출혈",
      carePlace: { home: 7.52, hospital: 92.48 },
      carePeriod: 9.33,
  },
  {
      diseaseName: "췌장암",
      carePlace: { home: 15.96, hospital: 84.04 },
      carePeriod: 6.83,
  },
  {
      diseaseName: "췌장염",
      carePlace: { home: 3.03, hospital: 96.97 },
      carePeriod: 6.52,
  },
  {
      diseaseName: "측만",
      carePlace: { home: 9.09, hospital: 90.91 },
      carePeriod: 7.09,
  },
  {
      diseaseName: "치매",
      carePlace: { home: 30.52, hospital: 69.48 },
      carePeriod: 7.28,
  },
  {
      diseaseName: "치질",
      carePlace: { home: 0.0, hospital: 100.0 },
      carePeriod: 5.0,
  },
  {
      diseaseName: "코로나",
      carePlace: { home: 5.95, hospital: 94.05 },
      carePeriod: 6.21,
  },
  {
      diseaseName: "타박상",
      carePlace: { home: 7.41, hospital: 92.59 },
      carePeriod: 6.48,
  },
  {
      diseaseName: "탈구",
      carePlace: { home: 0.0, hospital: 100.0 },
      carePeriod: 6.13,
  },
  {
      diseaseName: "탈수",
      carePlace: { home: 0.0, hospital: 100.0 },
      carePeriod: 7.27,
  },
  {
      diseaseName: "탈장",
      carePlace: { home: 0.0, hospital: 100.0 },
      carePeriod: 4.03,
  },
  {
      diseaseName: "탈출",
      carePlace: { home: 0.0, hospital: 100.0 },
      carePeriod: 4.44,
  },
  {
      diseaseName: "통증",
      carePlace: { home: 4.92, hospital: 95.08 },
      carePeriod: 6.91,
  },
  {
      diseaseName: "통풍",
      carePlace: { home: 78.57, hospital: 21.43 },
      carePeriod: 3.07,
  },
  {
      diseaseName: "퇴행성관절염",
      carePlace: { home: 9.09, hospital: 90.91 },
      carePeriod: 6.06,
  },
  {
      diseaseName: "투석",
      carePlace: { home: 6.82, hospital: 93.18 },
      carePeriod: 6.53,
  },
  {
      diseaseName: "파열",
      carePlace: { home: 1.42, hospital: 98.58 },
      carePeriod: 4.65,
  },
  {
      diseaseName: "파킨슨",
      carePlace: { home: 35.38, hospital: 64.62 },
      carePeriod: 6.81,
  },
  {
      diseaseName: "판막수술",
      carePlace: { home: 0.0, hospital: 100.0 },
      carePeriod: 6.08,
  },
  {
      diseaseName: "팔골절",
      carePlace: { home: 8.62, hospital: 91.38 },
      carePeriod: 6.34,
  },
  {
      diseaseName: "패혈증",
      carePlace: { home: 8.7, hospital: 91.3 },
      carePeriod: 7.63,
  },
  {
      diseaseName: "편마비",
      carePlace: { home: 21.14, hospital: 78.86 },
      carePeriod: 9.77,
  },
  {
      diseaseName: "폐결핵",
      carePlace: { home: 0.0, hospital: 100.0 },
      carePeriod: 9.08,
  },
  {
      diseaseName: "폐렴",
      carePlace: { home: 1.71, hospital: 98.29 },
      carePeriod: 7.24,
  },
  {
      diseaseName: "폐부종",
      carePlace: { home: 0.0, hospital: 100.0 },
      carePeriod: 6.79,
  },
  {
      diseaseName: "폐색",
      carePlace: { home: 0.0, hospital: 100.0 },
      carePeriod: 6.19,
  },
  {
      diseaseName: "폐섬유",
      carePlace: { home: 2.7, hospital: 97.3 },
      carePeriod: 6.14,
  },
  {
      diseaseName: "폐암",
      carePlace: { home: 11.8, hospital: 88.2 },
      carePeriod: 6.46,
  },
  {
      diseaseName: "폐이식",
      carePlace: { home: 0.0, hospital: 100.0 },
      carePeriod: 11.68,
  },
  {
      diseaseName: "폐질환",
      carePlace: { home: 10.98, hospital: 89.02 },
      carePeriod: 10.29,
  },
  {
      diseaseName: "피부암",
      carePlace: { home: 0.0, hospital: 100.0 },
      carePeriod: 5.19,
  },
  {
      diseaseName: "피부이식",
      carePlace: { home: 0.0, hospital: 100.0 },
      carePeriod: 6.6,
  },
  {
      diseaseName: "하반신마비",
      carePlace: { home: 10.87, hospital: 89.13 },
      carePeriod: 12.27,
  },
  {
      diseaseName: "합병증",
      carePlace: { home: 2.99, hospital: 97.01 },
      carePeriod: 8.01,
  },
  {
      diseaseName: "항암",
      carePlace: { home: 8.17, hospital: 91.83 },
      carePeriod: 5.12,
  },
  {
      diseaseName: "허리골절",
      carePlace: { home: 28.36, hospital: 71.64 },
      carePeriod: 8.27,
  },
  {
      diseaseName: "허리디스크",
      carePlace: { home: 4.41, hospital: 95.59 },
      carePeriod: 5.83,
  },
  {
      diseaseName: "허리디스크수술",
      carePlace: { home: 0.0, hospital: 100.0 },
      carePeriod: 5.54,
  },
  {
      diseaseName: "허리수술",
      carePlace: { home: 4.13, hospital: 95.87 },
      carePeriod: 6.88,
  },
  {
      diseaseName: "허리염좌",
      carePlace: { home: 77.27, hospital: 22.73 },
      carePeriod: 5.36,
  },
  {
      diseaseName: "허리협착증",
      carePlace: { home: 11.9, hospital: 88.1 },
      carePeriod: 5.86,
  },
  {
      diseaseName: "혈관질환",
      carePlace: { home: 0.0, hospital: 100.0 },
      carePeriod: 5.88,
  },
  {
      diseaseName: "혈변",
      carePlace: { home: 0.0, hospital: 100.0 },
      carePeriod: 3.8,
  },
  {
      diseaseName: "혈액암",
      carePlace: { home: 1.27, hospital: 98.73 },
      carePeriod: 7.39,
  },
  {
      diseaseName: "혈액투석",
      carePlace: { home: 25.93, hospital: 74.07 },
      carePeriod: 6.78,
  },
  {
      diseaseName: "혈전",
      carePlace: { home: 13.95, hospital: 86.05 },
      carePeriod: 7.35,
  },
  {
      diseaseName: "협심증",
      carePlace: { home: 0.0, hospital: 100.0 },
      carePeriod: 4.67,
  },
  {
      diseaseName: "협착",
      carePlace: { home: 7.65, hospital: 92.35 },
      carePeriod: 6.14,
  },
  {
      diseaseName: "협착증",
      carePlace: { home: 7.85, hospital: 92.15 },
      carePeriod: 6.29,
  },
  {
      diseaseName: "호흡곤란",
      carePlace: { home: 3.45, hospital: 96.55 },
      carePeriod: 8.07,
  },
  {
      diseaseName: "호흡기질환",
      carePlace: { home: 0.0, hospital: 100.0 },
      carePeriod: 5.24,
  },
  {
      diseaseName: "호흡부전",
      carePlace: { home: 57.14, hospital: 42.86 },
      carePeriod: 20.25,
  },
  {
      diseaseName: "화상",
      carePlace: { home: 1.33, hospital: 98.67 },
      carePeriod: 10.31,
  },
  {
      diseaseName: "황달",
      carePlace: { home: 0.0, hospital: 100.0 },
      carePeriod: 5.73,
  },
  {
      diseaseName: "회복",
      carePlace: { home: 9.28, hospital: 90.72 },
      carePeriod: 6.77,
  },
  {
      diseaseName: "회전근개파열",
      carePlace: { home: 0.0, hospital: 100.0 },
      carePeriod: 4.36,
  },
  {
      diseaseName: "후두암",
      carePlace: { home: 3.23, hospital: 96.77 },
      carePeriod: 7.06,
  },
  {
      diseaseName: "후유증",
      carePlace: { home: 8.82, hospital: 91.18 },
      carePeriod: 7.1,
  },
  {
      diseaseName: "흉수",
      carePlace: { home: 0.0, hospital: 100.0 },
      carePeriod: 5.28,
  },
  {
      diseaseName: "흡인성폐렴",
      carePlace: { home: 8.06, hospital: 91.94 },
      carePeriod: 6.84,
  },
]