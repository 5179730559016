<template>
  <main>
    <article class="subBan qna">
      <div class="basicWrap">
        <h2>문의사항</h2>
        <p>
          케어네이션 관련하여 궁금하신 점이 있으신가요?<br />
          케어네이션 관련 문의사항을 남겨주세요.
        </p>
      </div>
    </article>
    <section class="question">
      <div class="question__mobileTab clear">
        <button type="button" @click="tabCurrent = 1" :class="tabCurrent == 1 ? 'active' : ''">카카오톡</button>
        <button type="button" @click="tabCurrent = 2" :class="tabCurrent == 2 ? 'active' : ''">유선</button>
        <button type="button" @click="tabCurrent = 3" :class="tabCurrent == 3 ? 'active' : ''">이메일</button>
      </div>
      <div class="basicWrap">
        <div class="question__list clear">
          <div>
            <div class="question__list--item" :class="tabCurrent == 1 ? 'active' : ''">
              <small>01</small>
              <h3>카카오톡으로 상담하기</h3>
              <p>카카오톡 상담 시간에는 카카오톡으로도 상담을 신청하실 수 있습니다.</p>
              <div class="greyBgBox">
                <h4>카카오톡 상담시간</h4>
                <dl>
                  <div class="clear">
                    <dt>월 ~ 토요일 :</dt>
                    <dd>오전 10시 ~ 오후 5시 30분</dd>
                  </div>
                  <div class="clear">
                    <dt>금요일 :</dt>
                    <dd>오전 10시 ~ 오후 5시</dd>
                  </div>
                  <div class="clear">
                    <dt>휴무일 :</dt>
                    <dd>일요일, 명절, 근로자의 날</dd>
                  </div>
                </dl>
              </div>
              <a href="https://pf.kakao.com/_QaGxjxb/chat" class="contactBtn kakao">카카오톡으로 상담하기</a>
            </div>
            <div class="question__list--item" :class="tabCurrent == 2 ? 'active' : ''">
              <small>02</small>
              <h3>유선으로 상담하기</h3>
              <p>
                고객센터 운영시간에는 유선 상담을 신청하실 수 있습니다.<br />
                <b>케어네이션 고객센터 1811 - 5949</b>로 전화를 걸어보세요!
              </p>
              <div class="greyBgBox">
                <h4>고객센터 상담시간</h4>
                <dl>
                  <div class="clear">
                    <dt>월 ~ 토요일 :</dt>
                    <dd>오전 10시 ~ 오후 5시 30분</dd>
                  </div>
                  <div class="clear">
                    <dt>금요일 :</dt>
                    <dd>오전 10시 ~ 오후 5시</dd>
                  </div>
                  <div class="clear">
                    <dt>휴무일 :</dt>
                    <dd>일요일, 명절, 근로자의 날</dd>
                  </div>
                </dl>
              </div>
            </div>
          </div>
          <div class="question__list--item" :class="tabCurrent == 3 ? 'active' : ''">
            <small>03</small>
            <h3>이메일로 상담하기</h3>
            <div class="contactForm">
              <ul class="contactForm__list clear">
                <li>
                  <label for="txt1_1">이름<span>(필수)</span></label>
                  <input type="text" id="txt1_1" placeholder="이름을 입력해주세요." v-model="contactName"/>
                </li>
                <li>
                  <label for="txt2_1">연락처<span>(필수)</span></label>
                  <input type="text" id="txt2_1" placeholder="연락처를 입력해주세요." v-model="contactPhone"/>
                </li>
                <li>
                  <label for="txt3_1">이메일<span>(필수)</span></label>
                  <input type="email" id="txt3_1" placeholder="이메일을 입력해주세요." v-model="contactEmail"/>
                </li>
                <li>
                  <label for="txt4_1">문의사항<span>(필수)</span></label>
                  <textarea id="txt4_1" placeholder="문의사항을 입력해주세요." v-model="contactContent"></textarea>
                </li>
              </ul>
              <button type="button" class="contactBtn submit" @click="checkForm()">접수하기</button>
            </div>
          </div>
        </div>
      </div>
    </section>
    <aside class="popup" :class="popup.classActive == 'active' ? 'active' : ''">
      <div class="popup__wrap cs">
        <div class="popup__wrap--tit" >
          <img src="@/assets/img/icon_success.png" alt="" aria-hidden v-if="popup.type == 'success'"/>
          <img src="@/assets/img/icon_fail.png" alt="" aria-hidden v-if="popup.type == 'fail'"/>
          <h2 v-if="popup.type == 'success'">접수가 완료되었습니다.</h2>
          <h2 v-if="popup.type == 'fail'">접수에 실패했습니다.</h2>
          <!-- <p>문의해주셔서 감사합니다.</p>
          <p>필수 정보를 모두 입력해주세요.</p> -->
          <p>{{popup.msg}}</p>
        </div>
        <div class="popup__wrap--btn">
          <button type="button" @click="popupOff()">확인</button>
        </div>
      </div>
    </aside>
    <div class="loadingBg" :class="contactDone ? 'active' : ''">
      <div class="loadingWrap">
        <span class="loading"></span>
        <h2>접수 중 입니다.</h2>
      </div>
    </div>
  </main>
</template>

<script>
import Api from '../api/index';
export default {
  data() {
    return {
      tabCurrent : 1, // 현재 탭 상태
      contactName : null, // 사용자 입력 이름
      contactPhone : null, // 사용자 입력 연락처
      contactEmail : null, // 사용자 입력 이메일
      contactContent : null, // 사용자 입력 상담내용
      phoneCheck : false, // 연락처 양식 통과 여부
      defaultMsg : '잠시 후 다시 시도해주세요.',
      currentStat : '', // 포커스 입력값
      InquireData : {
        name: '', //## 이름
        phone: '', //## 핸드폰 번호
        email: '', //## 이메일
        msg: '' //## 문의사항
      },
      viewPosition : 0,
      popup : {
        classActive : '',
        type : '',
        msg : ''
      },
      contactDone: false, //문의 완료 여부
    }
  },
  methods: {
    checkForm() {
      if (this.contactName == null || this.contactName == '') {
        this.popup = {classActive: "active", type: "fail", msg: "성명을 입력해주세요."};
        this.currentStat = 'name'
      } else if (this.contactPhone == null || this.contactPhone == '') {
        this.popup = {classActive: "active", type: "fail", msg: "연락처를 입력해주세요."};
        this.currentStat = 'phone'
      } else if (this.contactEmail == null || this.contactEmail == '') {
        this.popup = {classActive: "active", type: "fail", msg: "이메일 주소를 입력해주세요."};
        this.currentStat = 'mail'
      } else if (this.contactContent == null || this.contactContent == '') {
        this.popup = {classActive: "active", type: "fail", msg: "문의사항을 입력해주세요."}
        this.currentStat = 'detail'
      } else {
        this.checkPhoneNumber();
        if(this.phoneCheck === true) {
          this.checkEmail();
        }
      }
    },
    checkPhoneNumber() {
      var phoneNum = this.contactPhone;
      var patternPhone = /^(01[016789]|02|0[3-9][0-9]{1,2})-?[0-9]{3,4}-?[0-9]{1,4}$/;
      if (!patternPhone.test(phoneNum)) {
        this.popup = {classActive: "active", type: "fail", msg: "연락처 형식이 맞지 않습니다."};
        this.phoneCheck = false;
        this.currentStat = 'phone';
        return;
      } else {
        this.phoneCheck = true
      }
    },
    checkEmail() {
      var emailAddr = this.contactEmail;
      // eslint-disable-next-line
      var regExpEmail = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
      if(emailAddr.length < 6 || !regExpEmail.test(emailAddr)) {
          this.popup = {classActive: "active", type: "fail", msg: "이메일 주소 형식이 맞지않습니다."};
          this.currentStat = 'mail'
          return;
      } else {
        if(this.phoneCheck === true) {
          this.sendContactApi();
        }
      }
    },
    sendContactApi() {
      this.contactDone = true;
      let formData = new FormData();
      formData.append("name", this.contactName);
      formData.append("phone", this.contactPhone);
      formData.append("email", this.contactEmail);
      formData.append("content", this.contactContent);
      Api.sendInquire(formData).then((response) => {
          if (response.status === 200) {
            let data = response.data;
            if (data.code === 200) {
              this.popup = {classActive: "active", type: "success", msg: "문의해주셔서 감사합니다."};
              this.contactName = '';
              this.contactPhone = '';
              this.contactEmail = '';
              this.contactContent = '';
              this.contactDone = false
            } else {
              this.popup = {classActive: "active", type: "fail", msg: this.defaultMsg};
            }
          } else {
            this.popup = {classActive: "active", type: "fail", msg: this.defaultMsg}
          }
        }).catch((err) => {
          console.log(err);
          this.popup = {classActive: "active", type: "fail", msg: this.defaultMsg}
        });
    },
    popupOff() {
      const agreeSection = document.querySelector('.contactInfo');
      const nameBox = document.querySelector('#txt1_1');
      const phoneBox = document.querySelector('#txt2_1');
      const mailBox = document.querySelector('#txt3_1');
      const detailBox = document.querySelector('#txt4_1');
      this.popup.classActive = '';
      switch(this.currentStat) {
        case 'check' : // if(currentStat === 'check')
          agreeSection.scrollIntoView({  block: 'start', behavior: 'smooth' });
        break;
        case 'name' : // if(currentStat === 'name')
          nameBox.focus({behavior: 'smooth'});
        break;
        case 'phone' : // if(currentStat === 'phone')
          phoneBox.focus({behavior: 'smooth'});
        break;
        case 'mail' : // if(currentStat === 'mail')
          mailBox.focus({behavior: 'smooth'});
        break;
        case 'detail' : // if(currentStat === 'detail')
          detailBox.focus({behavior: 'smooth'});
        break;
      }
    }
  }
}
</script>
<style scoped>
  .popup {
    display: none;
  }
  .popup.active {
    display: block;
  }
  .loadingBg {
    display: none;
    top: 0;
    right: 0;
  }
  .loadingBg.active {
    display: block;
  }
</style>