<template>
  <canvas ref="chart"></canvas>
</template>

<script>
import * as Utils from '../../constants/utils';
import Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
export default {
  props: {
    countNum: Number,
    diseaseList: Array,
  },
  data() {
    return {
      myChart: null,
    };
  },
  mounted() {
    const canvas = this.$refs.chart;
    canvas.width = canvas.offsetWidth;
    canvas.height = canvas.offsetHeight;
    const isMiddle = function (context) {
      return context.dataIndex === 1;
    };
    const ctx = canvas.getContext('2d');
    const fill =
      window.innerWidth >= 1024
        ? 140
        : window.innerWidth < 1024 && window.innerWidth >= 800
        ? 400
        : window.innerWidth < 800 && window.innerWidth >= 600
        ? 300
        : window.innerWidth < 600 && window.innerWidth >= 400
        ? 160
        : 120;
    const circle = window.innerWidth >= 1321 ? 6 : 4;
    const chartY = window.innerWidth >= 1024 ? -25 : window.innerWidth < 1024 && window.innerWidth >= 360 ? -30 : -10;
    const gradientFill = ctx.createLinearGradient(0, 0, 0, fill);
    gradientFill.addColorStop(0, 'rgba(255,209,190,1)');
    gradientFill.addColorStop(1, 'rgba(255,255,255,0)');
    this.myChart = new Chart(ctx, {
      type: 'line',
      data: {
        labels: ['', '', ''],
        datasets: [
          {
            data: [this.diseaseList[this.countNum].min, this.diseaseList[this.countNum].average, this.diseaseList[this.countNum].max],
            fill: true,
            borderWidth: 2,
            borderColor: '#7B4E3D',
            pointRadius: circle,
            pointBorderWidth: 3,
            pointBorderColor: '#7B4E3D',
            pointBackgroundColor: function (context) {
              return isMiddle(context) ? '#7B4E3D' : '#fff';
            },
            pointHoverBorderColor: '#7B4E3D',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderWidth: 4,
            pointHoverRadius: 8,
            backgroundColor: gradientFill,
            tension: 0,
          },
        ],
      },
      options: {
        scales: {
          yAxes: [
            {
              ticks: {
                display: false,
                maxTicksLimit: 3,
                min: 100000,
                beginAtZero: true,
              },
              gridLines: {
                display: false,
              },
            },
          ],
          xAxes: [
            {
              ticks: {
                fontColor: '#333',
                fontSize: 12,
                fontStyle: 500,
                lineHeight: 1.5,
                fontFamily: 'Noto Sans Kr',
              },
              gridLines: {
                display: false,
              },
              scaleLabel: {
                display: true,
              },
            },
          ],
        },
        layout: {
          padding: {
            top: 30,
            bottom: chartY,
            left: 30,
            right: 40,
          },
        },
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
        },
        plugins: {
          datalabels: {
            anchor: 'end',
            align: 'top',
            offset: 0,
            color: '#7B4E3D',
            font: (context) => (isMiddle(context) ? { weight: '900' } : { weight: '600' }),
            formatter: function (value) {
              return Utils.numberWithCommas(value) + '원';
            },
          },
        },
      },
      plugins: [
        ChartDataLabels,
        {
          beforeDatasetDraw: (chart) => {
            const {
              ctx,
              chartArea: { bottom },
            } = chart;

            const datasetMeta = chart.getDatasetMeta(0);
            if (datasetMeta && datasetMeta.data.length > 0) {
              const dataPoint = datasetMeta.data[0];
              if (dataPoint) {
                ctx.beginPath();
                ctx.strokeStyle = '#7B4E3D';
                ctx.lineWidth = 2;
                ctx.setLineDash([2, 2]); // 대시 패턴 설정
                ctx.moveTo(
                  (dataPoint._chart.chartArea.right + dataPoint._chart.chartArea.left) / 2,
                  dataPoint._chart.$datalabels._labels[1].$layout._box._rect.y + dataPoint._chart.$datalabels._labels[1].$layout._box._rect.h,
                );
                ctx.lineTo((dataPoint._chart.chartArea.right + dataPoint._chart.chartArea.left) / 2, bottom);
                ctx.stroke();
                ctx.closePath();
                ctx.setLineDash([]); // 대시 패턴 해제
              }
            }
          },
        },
      ],
    });
  },
};
</script>
