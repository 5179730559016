<template>
  <main>
    <section class="service care">
      <article class="subMain mobileBg">
        <div class="basicWrap clear">
          <div class="subMain__tit">
            <h2>내가 직접 찾는<br />간병 일자리!</h2>
            <p>
              원하는 날짜/지역/병원을 직접 선택!<br />
              간병 일감 찾기를 누르셔서 앱 다운로드 후<br />
              내 도움이 필요한 환자를 케어해주세요!
            </p>
            <a target="_blank" :href="googleStore" class="findBtn" v-if="this.storeInfo == 'window' || this.storeInfo == 'android'">바로 간병일감 찾기</a>
            <a target="_blank" :href="appleStore" class="findBtn" v-if="this.storeInfo == 'mac' || this.storeInfo == 'ios' || this.storeInfo == 'ipad'"
              >바로 간병일감 찾기</a
            >
            <div class="subMain__tit--storeBtn">
              <a target="_blank" :href="googleStore" class="google">구글 플레이 스토어</a>
              <a target="_blank" :href="appleStore" class="apple">앱 스토어</a>
            </div>
          </div>
          <div class="subMain__img">
            <img src="@/assets/img/img_submain_02.png" alt="내가 직접 찾는 간병 일자리!" />
          </div>
        </div>
      </article>
      <!-- <article class="videoSlideWrap">
        <div class="basicWrap">
          <div class="subMain__tit">
            <h2>
              보다 좋은 간병 문화를 만드는<br />
              케어네이션
            </h2>
          </div>
          <div class="videoSlideWrap__frame clear">
            <div class="videoSlideWrap__frame--tit">
              <h3>
                {{products[productNum].title}}
              </h3>
            </div>
            <iframe
              width="100%"
              height="100%"
              :src="products[productNum].video"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <ul class="videoSlideWrap__frame--sumnail">
            <li @click="productNumChange(1)" v-if="productNum !== 1">
              <img src="@/assets/img/img_sum04.png" alt="간병 시작 전 알아두어야 할 마음가짐" />
            </li>
            <li @click="productNumChange(2)" v-if="productNum !== 2">
              <img src="@/assets/img/img_sum05.png" alt="간병 준비물, 강추템 꼭 챙기세요!" />
            </li>
            <li @click="productNumChange(3)" v-if="productNum !== 3">
              <img src="@/assets/img/img_sum06.png" alt="병원에서 의료폐기물 제대로 버리는 방법!" />
            </li>
            <li @click="productNumChange(0)" v-if="productNum !== 0">
              <img src="@/assets/img/img_sum07.png" alt="친구같은 간병인이 되어보세요!" />
            </li>
          </ul>
        </div>
      </article> -->
      <article class="service__reason">
        <div class="basicWrap">
          <div class="subMain__tit">
            <h2>
              보다 편한 간병,<br />
              케어네이션이 좋은 이유!
            </h2>
          </div>
          <div class="service__reason--check"></div>
        </div>
        <div class="fullSlideWrap">
          <slick :options="slickOptions01" ref="slick" class="fullSlideWrap__content">
            <div class="fullSlideWrap__content--con con05">
              <small>01</small>
              <h3>
                직접 찾아보고 선택하는<br />
                간병일감!
              </h3>
              <p>원하는 날짜/지역/병원/환자를 직접 선택하세요!</p>
            </div>
            <div class="fullSlideWrap__content--con con06">
              <small>02</small>
              <h3>
                원하는 간병비를<br />
                직접 제시하세요!
              </h3>
              <p>정해진 간병비가 아니라 원하는 금액을 직접 제시하세요!</p>
            </div>
            <div class="fullSlideWrap__content--con con07">
              <small>03</small>
              <h3>
                간병 완료 시<br />
                간병비 바로 지급!
              </h3>
              <p>
                하루 간병 완료시 간병비를 당일 지급하며<br />
                앱 통장에서 쉽게 이체 가능합니다!
              </p>
            </div>
            <div class="fullSlideWrap__content--con con08">
              <small>04</small>
              <h3>
                일한 날만 지불하는<br />
                보험료!
              </h3>
              <p>
                케어네이션은 일한 날만<br />
                수수료와 보험료를 지불하면 됩니다!
              </p>
            </div>
          </slick>
          <div class="slideBtnWrap">
            <button class="prev">이전</button>
            <button class="next">다음</button>
          </div>
        </div>
      </article>
      <article class="service__step">
        <div class="basicWrap">
          <div class="subMain__tit">
            <h2>
              간편하게 관리할 수 있는<br />
              나의 간병비
            </h2>
            <p>핸드폰으로 간병비 받고 쉽게 내 통장으로 이체할 수 있어요!</p>
          </div>
          <ul>
            <li class="step04">
              <h3>간병인 앱 최초! 앱 통장!</h3>
              <p>
                간병 비용 관리하기 불편하셨죠?<br />
                스마트폰으로 통장을 쉽게 관리하세요.
              </p>
            </li>
            <li class="step05">
              <h3>앱통장으로 간편한 계좌이체!</h3>
              <p>
                내 계좌는 물론, 가족 계좌까지<br />
                원하는 계좌로 이체가 가능합니다.
              </p>
            </li>
            <li class="step06">
              <h3>자주 쓰는 계좌는 즐겨찾기!</h3>
              <p>
                즐겨찾기로 등록하여<br />
                손쉽게 계좌이체할 수 있습니다.
              </p>
            </li>
          </ul>
        </div>
      </article>
      <article class="service__review">
        <div class="basicWrap">
          <div class="subMain__tit">
            <h2>
              간병인 분들의<br />
              소중한 앱 사용 후기
            </h2>
            <p>
              이미 많은 간병인분들께서 큰 만족감을 느끼면서<br />
              케어네이션을 사용하고 계십니다.
            </p>
          </div>
        </div>
        <div class="cardSlideWrap">
          <div class="basicWrap">
            <slick class="cardSlideWrap__con" :options="slickOptions02">
              <div class="cardSlideWrap__con--list">
                <div>
                  <p>
                    환자 상태를 구체적으로 알고 가니까<br />
                    보호자랑 소통하기가 편해요.
                  </p>
                  <i class="star01"></i>
                </div>
                <div class="clear">
                  <figure class="profile05"></figure>
                  <div>
                    <h3>공*은 간병인</h3>
                    <p>서울아산병원</p>
                  </div>
                </div>
              </div>
              <div class="cardSlideWrap__con--list">
                <div>
                  <p>
                    간병비 당일에 바로 주는 게 제일 좋네요.<br />
                    간병인으로 오래 일했는데<br />
                    이런 앱 신기해요!
                  </p>
                  <i class="star02"></i>
                </div>
                <div class="clear">
                  <figure class="profile06"></figure>
                  <div>
                    <h3>박*태 간병인</h3>
                    <p>인제대학교 상계백병원</p>
                  </div>
                </div>
              </div>
              <div class="cardSlideWrap__con--list">
                <div>
                  <p>
                    가입하면 바로 앱통장이 생겨서 편하네요.<br />
                    은행 안 가도 되고 병실에서<br />
                    계좌이체도 할 수 있어 좋아요.
                  </p>
                  <i class="star01"></i>
                </div>
                <div class="clear">
                  <figure class="profile03"></figure>
                  <div>
                    <h3>김*오 간병인</h3>
                    <p>삼성서울병원</p>
                  </div>
                </div>
              </div>
              <div class="cardSlideWrap__con--list">
                <div>
                  <p>
                    센터에서 콜만 기다리다<br />
                    스케줄에 맞는 환자를 찾아서<br />
                    케어할 수 있다니 참 좋네요.
                  </p>
                  <i class="star01"></i>
                </div>
                <div class="clear">
                  <figure class="profile04"></figure>
                  <div>
                    <h4>최*윤 간병인</h4>
                    <p>삼성서울병원</p>
                  </div>
                </div>
              </div>
            </slick>
          </div>
          <div class="slideBtnWrap">
            <button type="button" class="prev">이전</button>
            <button type="button" class="next">다음</button>
          </div>
        </div>
      </article>
    </section>
  </main>
</template>

<script>
import * as Utils from '../../constants/utils';
import Slick from 'vue-slick';
import 'slick-carousel/slick/slick.css';
import findPtr from '@/assets/js/findPtr';
export default {
  components: { Slick },
  data() {
    return {
      storeInfo: Utils.storeType(),
      googleStore: 'https://opqb4.app.link/t21Z4zZM0Jb',
      appleStore: 'https://opqb4.app.link/8X3pLowN0Jb',
      productNum: 0,
      // products : [
      //   {title: '친구 같은 간병인이 되어보세요!', imgSrc: require('@/assets/img/img_sum01.png'), video: 'https://www.youtube-nocookie.com/embed/h3jbZF8OFC8' },
      //   {title: '간병 시작 전 알아두어야 할 마음가짐', imgSrc: require('@/assets/img/img_sum01.png'), video: 'https://www.youtube-nocookie.com/embed/KfE23DAujEU' },
      //   {title: '간병 준비물, 강추템 꼭 챙기세요!', imgSrc: require('@/assets/img/img_sum01.png'), video: 'https://www.youtube-nocookie.com/embed/QoFk91YqjwY' },
      //   {title: '병원에서 의료폐기물 제대로 버리는 방법!', imgSrc: require('@/assets/img/img_sum01.png'), video: 'https://www.youtube-nocookie.com/embed/Zx7VpUxjYY4' },
      // ],
      findPtr: findPtr,
      slickOptions01: {
        slidesToShow: 1,
        draggable: false,
        prevArrow: '.service__reason .prev',
        nextArrow: '.service__reason .next',
        dots: true,
        dotsClass: 'clearfix',
        appendDots: '.service__reason--check',
        customPaging: function (slide, index) {
          return '<button type="button">' + findPtr[index].title + '</button>';
        },
      },
      slickOptions02: {
        slidesToShow: 3,
        draggable: false,
        prevArrow: '.cardSlideWrap .prev',
        nextArrow: '.cardSlideWrap .next',
        responsive: [
          {
            breakpoint: 1023,
            settings: {
              slidesToShow: 2,
              draggable: true,
            },
          },
          {
            breakpoint: 723,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      },
    };
  },
  methods: {
    // productNumChange(index) {
    //   this.productNum = index;
    // }
  },
};
</script>

<style>
.caregiver .slick-active:after {
  width: 100%;
  height: 4px;
  background-color: #386392 !important;
  opacity: 1 !important;
}
</style>
