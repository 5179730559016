export default [
  {
    title:"쉽고 빠른 간병인 찾기"
  },
  {
    title:"내국인 간병인 98%"
  },
  {
    title:"간병비 결제 증빙"
  },
  {
    title:"간병비 정찰제"
  }
]
