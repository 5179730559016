<template>
  <main>
    <section class="corpor">
      <article class="subMain mobileBg">
        <div class="basicWrap clear">
          <div class="subMain__tit">
            <h2>
              4년 연속 소비자만족도 1위<br />
              전국 간병인 매칭 서비스!
            </h2>
            <p>
              병원, 방문요양센터, 보험사, 간병업체도<br />
              케어네이션으로 고객에게 제공할 간병인을<br />
              빠르게 찾을 수 있습니다.
            </p>
            <a :href="enterLink" class="findBtn">기업회원으로 간병인 찾기</a>
          </div>
          <div class="subMain__img">
            <img src="@/assets/img/img_submain_04.png" alt="기업도 이용 가능한 간병인 매칭 서비스!" />
          </div>
        </div>
      </article>
      <article class="corpor__cardList">
        <div class="basicWrap">
          <div class="subMain__tit">
            <span>눌러서 확인해보세요!</span>
            <h2>
              기업에서<br />
              간병인이 필요한<br />
              다양한 상황
            </h2>
          </div>
          <ul class="corpor__cardList--card" ref="scrollable" @scroll="cardScroll()">
            <li class="card01" @click="popupOnOn(1)">
              <figure></figure>
              <div>
                <h3>병원</h3>
                <p>
                  ‘개인’ 간병인이 필요한 환자에게<br />
                  빠르게 간병인을 투입할 수 있습니다.
                </p>
                <span>내용 더보기</span>
              </div>
            </li>
            <li class="card02" @click="popupOnOn(2)">
              <figure></figure>
              <div>
                <h3>요양센터</h3>
                <p>방문 요양 시간 외 추가 간병인이 <br />필요한 고객에게 <br />간병인을 제공해 보세요.</p>
                <span>내용 더보기</span>
              </div>
            </li>
            <li class="card03" @click="popupOnOn(3)">
              <figure></figure>
              <div>
                <h3>보험사</h3>
                <p>직접 간병인을 구해 고객에게 <br />제공이 가능하며, <br />지출 증빙이 간단합니다.</p>
                <span>내용 더보기</span>
              </div>
            </li>
            <li class="card04" @click="popupOnOn(4)">
              <figure></figure>
              <div>
                <h3>간병인 업체</h3>
                <p>고객에게 제공할 간병인이 <br />부족할 때, 빠르게 구하실 수 있습니다.</p>
                <span>내용 더보기</span>
              </div>
            </li>
          </ul>
          <div class="corpor__cardList--scrollBar">
            <span :style="{ width: widthValue }"></span>
          </div>
        </div>
      </article>
      <article class="service__reason">
        <div class="basicWrap">
          <div class="subMain__tit">
            <h2>
              기업회원에게<br />
              케어네이션이 좋은 이유!
            </h2>
          </div>
          <div class="service__reason--check"></div>
        </div>
        <div class="fullSlideWrap">
          <slick :options="slickOptions01" class="fullSlideWrap__content">
            <div class="fullSlideWrap__content--con con09">
              <small>01</small>
              <h3>
                전국 24시간<br />
                실시간 매칭 가능
              </h3>
              <p>
                - 국내 최다 58,000명 이상 간병인 회원 보유<br />
                - 공고 등록 24시간 이내 간병인 지원율 80%<br />
                - 3,100개의 의료기관에서 케어네이션 간병인 서비스 이용 중
              </p>
            </div>
            <div class="fullSlideWrap__content--con con10">
              <small>02</small>
              <h3>
                업계 최고<br />
                내국인 간병인 98%
              </h3>
              <p>
                - 간병인 플랫폼 기준, 업계 1위 내국인 비중<br />
                - 고객 만족도 높은 내국인 위주 간병인 구성<br />
                - 보호자의 간병인 만족도 평균 4.73점 (5점 만점 기준)
              </p>
            </div>
            <div class="fullSlideWrap__content--con con11">
              <small>03</small>
              <h3>
                편리한 간병비<br />
                결제 증빙!
              </h3>
              <p>
                - 업계 최초 PG사 결제 서비스 연동<br />
                - 기업 카드 결제로 간편한 결제 및 증빙<br />
                - 간병비 대리 결제 시 발생하는 법적/세무적 이슈 <br />
                해소를 위한 간병인 모집 위탁 동의서 제공
              </p>
            </div>
            <div class="fullSlideWrap__content--con con12">
              <small>04</small>
              <h3>
                기업 회원이 정하는<br />
                간병비 정찰제
              </h3>
              <p>
                - 기업에서 지불 가능한 간병비 설정 가능<br />
                - 케어네이션 데이터랩 통계기반 적정 간병비 자동 추천<br />
                - 간병비 선결제로 추가 간병비 요구 차단
              </p>
            </div>
          </slick>
          <div class="slideBtnWrap">
            <button type="button" class="prev">이전</button>
            <button type="button" class="next">다음</button>
          </div>
        </div>
      </article>
      <article class="corpor__ban">
        <div class="basicWrap">
          <h2>
            4년 연속 소비자만족도 1위<br />
            간병인 매칭 서비스니까
          </h2>
          <p>
            케어네이션은 시장과 정부에서 주목하는 간병 산업 혁신 기업으로,<br />
            <b>전국 22만 명 이상</b>의 보호자와 간병인이 선택한<br />
            <b>국내 1등 간병인 매칭 서비스</b>입니다.
          </p>
        </div>
      </article>
      <article class="corpor__step">
        <div class="basicWrap">
          <div class="subMain__tit">
            <h2>
              기업회원 전용<br />
              원스탑 서비스
            </h2>
            <p>
              맞춤 공고를 등록해 고객에게 제공할 간병인을 빠르게 찾아보세요.<br />
              케어네이션으로 간병인 매칭 업무 부담을 덜 수 있습니다.
            </p>
          </div>
          <ul class="corpor__step--list clear">
            <li class="corStep01">
              <h3>회원가입</h3>
              <div>
                <p>- 기업 정보 입력</p>
                <p>- 사업자등록증 첨부</p>
              </div>
            </li>
            <li class="corStep02">
              <h3>공고등록</h3>
              <div>
                <p>- 환자 정보 입력</p>
                <p>- 간병비 입력</p>
              </div>
            </li>
            <li class="corStep03">
              <h3>간병인 선택</h3>
              <div>
                <p>- 간병인 정보 확인</p>
                <p>- 간병인 선택</p>
              </div>
            </li>
            <li class="corStep04">
              <h3>결제 / 증빙</h3>
              <div>
                <p>- 서비스 완료 확인</p>
                <p>- 증빙 서류 확인</p>
              </div>
            </li>
          </ul>
        </div>
      </article>
      <article class="matchingSer">
        <div class="basicWrap">
          <h2>기업도 이용 가능한 간병인 매칭 서비스!</h2>
          <ul class="clear">
            <li>
              <router-link to="./company/join">
                <h3>기업회원 가입하기</h3>
                <p>간병인을 빠르게 찾아보세요!</p>
                <button type="button">바로가기</button>
              </router-link>
            </li>
            <li>
              <router-link to="/customer">
                <h3>상담 문의 남기기</h3>
                <p>궁금하신 점을 남겨보세요.</p>
                <button type="button">바로가기</button>
              </router-link>
            </li>
          </ul>
          <span>* 기업회원 서비스는 <b>PC에서만 사용 가능</b>합니다.</span>
        </div>
      </article>
    </section>
    <aside v-for="(popup, i) in 4" :key="i" class="slidePopup" :class="popupOn == i + 1 ? 'active' : ''">
      <div class="basicWrap">
        <div class="slidePopup__wrap">
          <p>
            <span>{{ pageNum[i] }}</span> / 4
          </p>
          <swiper class="slidePopup__wrap--list" :class="'list' + '0' + (i + 1)" :options="swiperOptions" :key="i" ref="mySwiper">
            <swiper-slide class="item"></swiper-slide>
            <swiper-slide class="item"></swiper-slide>
            <swiper-slide class="item"></swiper-slide>
            <swiper-slide class="item"></swiper-slide>
          </swiper>
          <div class="slidePopup__wrap--btn">
            <button type="button" class="prev">이전</button>
            <button type="button" class="next">다음</button>
          </div>
          <div class="slidePopup__wrap--close">
            <button type="button" @click="popupOff()">그만 볼래요</button>
          </div>
        </div>
      </div>
    </aside>
  </main>
</template>

<script>
import Slick from 'vue-slick';
import 'slick-carousel/slick/slick.css';
import { swiper, swiperSlide } from 'vue-awesome-swiper';
import 'swiper/dist/css/swiper.min.css';
import findEnter from '@/assets/js/findEnter';
const serverType = process.env.VUE_APP_SERVER_TYPE;
export default {
  components: {
    Slick,
    swiper,
    swiperSlide,
  },
  data() {
    return {
      enterLink:
        serverType == 'CI'
          ? 'https://ci-enterprise.carenation.kr/user/login'
          : serverType == 'QA'
          ? 'https://qa-enterprise.carenation.co.kr/user/login'
          : 'https://enterprise.carenation.kr/user/login', //## 서버 타입
      pageNum: [1, 1, 1, 1],
      activeIndex: '',
      popupOn: 0,
      widthValue: '0',
      slickOptions01: {
        slidesToShow: 1,
        draggable: false,
        prevArrow: '.fullSlideWrap .prev',
        nextArrow: '.fullSlideWrap .next',
        dots: true,
        dotsClass: 'clearfix',
        appendDots: '.service__reason--check',
        customPaging: function (slide, index) {
          return '<button type="button">' + findEnter[index].title + '</button>';
        },
      },
      swiperOptions: {
        initialSlide: 0,
        slidesPerView: 2,
        centeredSlides: true,
        draggable: false,
        navigation: {
          nextEl: '.slidePopup__wrap--btn .next',
          prevEl: '.slidePopup__wrap--btn .prev',
        },
        on: {
          slideChange: () => {
            this.slideSet();
          },
        },
        perspective: true,
        effect: 'coverflow',
        coverflowEffect: {
          slideShadows: true,
          rotate: 0,
          stretch: 70,
          scale: 0,
        },
      },
    };
  },
  methods: {
    popupOnOn(i) {
      this.slideSet();
      this.popupOn = i;
    },
    popupOff() {
      this.popupOn = 0;
      this.$refs.mySwiper[0].$el.swiper.activeIndex = 0;
      this.$refs.mySwiper[1].$el.swiper.activeIndex = 0;
      this.$refs.mySwiper[2].$el.swiper.activeIndex = 0;
      this.$refs.mySwiper[3].$el.swiper.activeIndex = 0;
    },
    slideSet() {
      this.pageNum = [1, 1, 1, 1];
      for (let i = 0; i < 4; i++) {
        const activeIndex = this.$refs.mySwiper[i].$el.swiper.activeIndex;
        this.pageNum[i] = activeIndex + 1;
      }
    },
    cardScroll() {
      const el = this.$refs.scrollable;
      const scrollLeft = el.scrollLeft;
      const scrollWidth = el.scrollWidth;
      const clientWidth = el.clientWidth;
      const scrollPercentage = Math.floor(((scrollWidth - scrollLeft - clientWidth) / scrollWidth) * 100);
      const resultPer = 100 - scrollPercentage;
      this.widthValue = resultPer + '%';
    },
  },
  mounted() {
    this.cardScroll(); // 초기화
  },
};
</script>
<style>
.slidePopup__item--cou span .default {
  display: none;
}
.slidePopup__item--cou span .active {
  display: block !important;
}
.slidePopup__item--list .swiper-slide {
  height: 500px;
  background-size: cover;
}
@media screen and (max-width: 1023px) {
  .slidePopup__item--list.list01 .swiper-slide {
    padding-bottom: 52%;
    height: auto;
  }
}
</style>
