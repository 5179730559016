import Vue from "vue";
import VueRouter from "vue-router";
import CommonTerms from "@/components/common/CommonTerms";
import MainPage from "@/components/main/MainPage";
import servProtector from "@/components/service/ServProtector";
import servCaregiver from "@/components/service/ServCaregiver";
import servPrecalc from "@/components/preCalc/ServPrecalc";
import servHospital from "@/components/service/ServHospital";
import untactPay from "@/components/service/UntactPay";
import ServCompany from "@/components/service/ServCompany";
import ServCompanyJoin from "@/components/service/ServCompanyJoin";
import customerCenter from "@/components/CustomerCenter";
import notFound from "@/components/common/notFound.vue";

Vue.use(VueRouter);

const route = [
  // 메인 페이지
  { path: "/", component: MainPage },
  { path: "/main", component: MainPage },

  // 약관
  { path: "/etc/termsProtector/clause", name: "protector", component: CommonTerms },
  { path: "/etc/policyProtector/clause", name: "protectorPrivacy", component: CommonTerms },
  { path: "/etc/termsCaregiver/clause", name: "caregiver", component: CommonTerms },
  { path: "/etc/policyCaregiver/clause", name: "caregiverPrivacy", component: CommonTerms },
  { path: "/etc/termsLocation/clause", name: "location", component: CommonTerms },

  // 서비스 페이지
  { path: "/service/protector", component: servProtector },
  { path: "/service/caregiver", component: servCaregiver },
  { path: "/service/precalc", component: servPrecalc },
  { path: "/service/hospital", component: servHospital },
  { path: "/service/company", component: ServCompany },
  { path: "/service/company/join", component: ServCompanyJoin },
  { path: "/service/untactpay", component: untactPay },

  // 고객센터
  { path: "/customer", component: customerCenter },

  // 에러페이지
  { path: "*", component: notFound },
];

// Vue 라우터 인스턴스 생성
const router = new VueRouter({
  mode: "history",
  routes: route,
  scrollBehavior: function (to, _from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      window.scrollTo(0, 0);
    }
  },
});

export default router;
