var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',[_c('section',{staticClass:"hospital"},[_vm._m(0),_vm._m(1),_vm._m(2),_c('article',{staticClass:"hospital__paySer"},[_c('div',{staticClass:"basicWrap"},[_vm._m(3),_vm._m(4),_vm._m(5),_c('slick',{staticClass:"hospital__paySer--list clear",attrs:{"options":_vm.slickOptions01}},[_c('div',{staticClass:"paystep step01"},[_c('h3',[_vm._v("병원 방문 없이 카드결제로!")]),_c('p',[_vm._v(" 비대면 결제로 편리하게"),_c('br'),_vm._v(" 간병비·병원비 결제가 가능합니다. ")])]),_c('div',{staticClass:"paystep step02"},[_c('h3',[_vm._v("나눠 결제하는 분할 납부")]),_c('p',[_vm._v(" 가족끼리 분담해서"),_c('br'),_vm._v(" 결제할 수 있습니다. ")])]),_c('div',{staticClass:"paystep step03"},[_c('h3',[_vm._v("빠르고 안전한 결제 시스템")]),_c('p',[_vm._v(" 금융감독원의 허가를 받은"),_c('br'),_vm._v(" PG 서비스로 안전합니다. ")])])])],1)]),_vm._m(6),_c('article',{staticClass:"matchingSer"},[_c('div',{staticClass:"basicWrap"},[_c('h2',[_vm._v("지금 시작해보세요!")]),_c('ul',{staticClass:"clear"},[_vm._m(7),_c('li',[_c('router-link',{attrs:{"to":"/customer"}},[_c('h3',[_vm._v("상담 문의 남기기")]),_c('p',[_vm._v("궁금하신 점을 남겨보세요.")]),_c('button',{attrs:{"type":"button"}},[_vm._v("바로가기")])])],1)]),_vm._m(8)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('article',{staticClass:"subMain mobileBg"},[_c('div',{staticClass:"basicWrap clear"},[_c('div',{staticClass:"subMain__tit"},[_c('h2',[_vm._v(" 간병비·병원비를 손쉽게"),_c('br'),_vm._v(" 청구할 수 있는 비대면 결제! ")]),_c('p',[_vm._v(" 케어네이션에서 제공하는"),_c('br'),_vm._v(" 관리자 페이지를 통해 비대면으로"),_c('br'),_vm._v(" 결제를 요청할 수 있습니다. ")]),_c('a',{staticStyle:{"cursor":"default"}},[_vm._v("비대면 결제 서비스 이용하기")])]),_c('div',{staticClass:"subMain__img"},[_c('img',{attrs:{"src":require("@/assets/img/img_submain_03.png"),"alt":"간병비·병원비를 손쉽게 청구할 수 있는 비대면 결제!"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('article',{staticClass:"hospital__why"},[_c('div',{staticClass:"basicWrap"},[_c('div',{staticClass:"subMain__tit"},[_c('h2',[_vm._v("왜 "),_c('span',[_vm._v("케어네이션")]),_vm._v("이어야 할까요?")]),_c('p',[_vm._v(" 병원의 고민을 함께 생각하고"),_c('br'),_vm._v(" 해결 방안을 제시합니다. ")])]),_c('ul',{staticClass:"comShadowBox"},[_c('li',[_c('h3',[_vm._v("초기 구축 비용 ZERO")]),_c('p',[_vm._v(" 바로 비대면 결제 관리 시스템을"),_c('br'),_vm._v(" 이용할 수 있습니다. ")])]),_c('li',[_c('h3',[_vm._v("운영 업무 간소화")]),_c('p',[_vm._v(" 미납 결제 내역을 쉽게"),_c('br'),_vm._v(" 파악할 수 있습니다. ")])]),_c('li',[_c('h3',[_vm._v("병원 이미지 개선")]),_c('p',[_vm._v(" 카드 및 할부 결제로"),_c('br'),_vm._v(" 보호자의 부담을 덜어줄 수 있습니다. ")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('article',{staticClass:"hospital__bgInfo"},[_c('div',{staticClass:"basicWrap"},[_c('h2',{staticClass:"a11y-hidden"}),_c('figure',[_c('img',{attrs:{"src":require("@/assets/img/img_notebook.png"),"alt":""}})]),_c('p',[_vm._v(" 관리자 페이지에서 환자 정보와 정산내역을 확인 후,"),_c('br'),_vm._v(" 문자 보내기를 통해 "),_c('b',[_vm._v("구매자에게 결제를 요청")]),_vm._v("할 수 있습니다."),_c('br'),_vm._v(" 문자를 받은 보호자는 케어네이션 앱으로"),_c('br'),_c('b',[_vm._v("간병비·병원비를 결제")]),_vm._v("할 수 있습니다. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"subMain__tit"},[_c('h2',[_vm._v("주머니 속"),_c('br'),_vm._v(" 병원 수납 창구 ")]),_c('p',[_vm._v(" 쉽고 간단한 비대면 결제 서비스로"),_c('br'),_vm._v(" 보호자에게 더 나은 서비스를 제공하세요. ")]),_c('small',[_vm._v("*보호자 결제 화면")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"scrolling"},[_c('span'),_c('span'),_c('span')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mobile induceScrolling"},[_c('span'),_c('span'),_c('span')])
},function (){var _vm=this,_c=_vm._self._c;return _c('article',{staticClass:"hospital__payStep"},[_c('div',{staticClass:"basicWrap"},[_c('div',{staticClass:"subMain__tit"},[_c('h2',[_vm._v("비대면 결제 진행 절차")]),_c('p',[_c('b',[_vm._v("간병비 대리 결제에 대한 부가세 문제")]),_vm._v("를"),_c('br'),_vm._v(" 케어네이션을 통해 덜어낼 수 있습니다. ")])]),_c('div',{staticClass:"hospital__payStep--img"},[_c('figure')])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{staticStyle:{"cursor":"default"}},[_c('h3',[_vm._v("병원회원 가입하기")]),_c('p',[_vm._v("결제가 쉬워집니다.")]),_c('button',{attrs:{"type":"button"}},[_vm._v("바로가기")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_vm._v("* 병원회원 서비스는 "),_c('b',[_vm._v("PC에서만 사용 가능")]),_vm._v("합니다.")])
}]

export { render, staticRenderFns }